import React from "react";
import {
  Col,
  message,
  Row,
  Upload,
} from "antd";
import {
  InboxOutlined,
} from "@ant-design/icons";

const { Dragger } = Upload;

const FormUploadDocument = (props) => {
  const uploadProps = {
    name: "file",
    action: "/api/admin/media",
    onChange(info) {
      const { status } = info.file;
      console.log("--- info fileList", info.fileList);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Row>
      <Col span={24}>
        <Dragger
          {...uploadProps}
          data={{ scope: "public" }} // for /data/public/media
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Only support [.pdf]
          </p>
        </Dragger>
      </Col>
    </Row>
  );
};

export default FormUploadDocument;
