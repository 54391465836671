import {
  Button, Form, Input, Modal, Space, Tabs, message
} from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as Service from "../../../core/Service";


const { TextArea } = Input;
const { TabPane } = Tabs;

const NotificationModal = (props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(false);
  // Get param from props
  const {
    modalVisible,
    setModalVisible,
    selectedRecord,
    setSelectedRecord,
    forceRefresh,
    setForceRefresh,
  } = props;

  // double "!!" means case to boolean type
  const isAddMode = (!!(selectedRecord.notification_queue_id === undefined
  || selectedRecord.notification_queue_id === 0));

  useEffect(() => {
    if (selectedRecord.notification_queue_id === undefined
      || selectedRecord.notification_queue_id === 0) {
      setIsReadOnlyMode(false);
      form.resetFields();
      return;
    }

    form.setFieldsValue(selectedRecord);
    setIsReadOnlyMode(true);
  }, [selectedRecord]);

  // Functions
  const closeModal = () => {
    setModalVisible(false);
    setSelectedRecord({}); // remember to set empty
    form.resetFields();
    setIsReadOnlyMode(false);
  };

  const onFinish = async (formObj) => {
    let clonePostObj = _.clone(formObj);
    // create new notification
    let requestData = {
      title: clonePostObj.title,
      content: clonePostObj.content,
    };
    let resp = {};
    // detect function
    if (isAddMode) {
      resp = await Service.call("post", "/api/notification/queue", requestData);
    }

    if (resp.errorMessage) {
      message.resp(resp.errorMessage);
      setModalVisible(false);
      return;
    }
    message.success("success");
    setModalVisible(false);
    setForceRefresh(!forceRefresh);
  };

  return (
    <Modal
      title={isAddMode ? "add" : "edit"}
      visible={modalVisible}
      onCancel={closeModal}
      footer={false}
      bodyStyle={{padding: "12px 24px"}}
    >
      <Tabs
        defaultActiveKey="for_all_user"
        centered="true"
      >
        <TabPane tab="For All User" key="for_all_user">
          <GlobalForm
            form={form}
            onFinish={onFinish}
            isReadOnlyMode={isReadOnlyMode}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

const GlobalForm = (props) => {
  const {
    form,
    onFinish,
    isReadOnlyMode
  } = props;

  return (
    <div>
      <Form
        form={form}
        name="notification_create_form"
        onFinish={onFinish}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{
            required: true,
            message: "Please fill title"
          }]}
        >
          <Input disabled={isReadOnlyMode} />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          rules={[{
            required: true,
            message: "Please fill content"
          }]}
        >
          <TextArea rows={7} disabled={isReadOnlyMode} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
            >
              submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NotificationModal;
