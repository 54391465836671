import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  notification,
  Row,
  Col,
  PageHeader,
  Spin,
  message,
  Button
} from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import * as Service from "../../../core/Service";
// import Button from "../../../t9UIComponents/t9Button";
import QRcodeDummy from "../../../assets/QRcodeDummy.svg";
import EventQRReader from "./EventQRReader";
import EventQRScanner from "./EventQRScanner";

const selectedKey = "check_in";
const openKeys = "event_management";


const EventCheckIn = (props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [scannerType, setScannerType] = useState(null);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const title = t("check_in");
  const formData = {
    event_name: "New Product Item",
    event_date_time: "2022-08-15 13:00 - 14:00",
    ID: "0001",
    email_address: "12345678@gmail.com",
    phone_number: "852 98765432",
    last_name: "Wong",
    first_name: "Peter",
  };
  const styles = {
    button: {
      background: "#308DDE",
      boarderRadius: "4px",
      color: "white",
      width: "77px",
      height: "33px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      fontSize: 16,
      paddingBottom: 8,
      borderBottom: "0.5px solid #D4D4D4",
      color: "#1E73BE",
      fontWeight: 700,
      lineHeight: "24px"
    },
    data: {
      color: "#1E73BE",
      AlignSelf: "center"
    }
  };


  const onScan = async (value) => {
    console.log("on scan value >>>", value);
    // setModalVisible
    setUserData({
      event_name: "event 1",
      event_date_time: "22/09/2022",
      id: "7",
      email_address: "joe.yuen@technine.io",
      phone_number: "98765432",
      last_name: "Yuen",
      first_name: "Joe",
    });

    if (loading) {
      return;
    }
    setLoading(true);
    try {
      // console.log(`navigateCallback value >> `, value)
      // const resp = await Service.call('post', `/api/user/passcode/checkin`, {
      //   encryptedPasscodeData: value,
      // });
      // console.log(`navigateCallback resp >> `, resp);
      // const { status, errorCode, errorMessage } = resp;
      // if (status !== 1) {
      //   message.error(errorMessage);
      //   return;
      // }
      const text = undefined;
      if (value == text) {
        return;
      }
      setModalVisible(true);
      setUserData(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[16, 24]}>
        <div style={{}}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => setScannerType("reader")}
            >
              {t("scan_to_check_in")}

            </Button>
          </Col>
        </div>
        <div style={{ justifyContent: "center" }}>
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => {
                setScannerType("scanner");
                console.log("event scanner active");
              }}
            >
              {t("scan_to_check_in_plugin")}
            </Button>
          </Col>
        </div>
      </Row>

      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {scannerType === "reader" && (
            <EventQRReader
              onScan={onScan}
              scanDelay={2000}
              footer={null}
            />
          )}

          {scannerType === "scanner" && (

            <EventQRScanner
              onScan={onScan}
              footer={null}
            />
          )}
        </Col>
      </Row>
      <Modal

        destroyOnClose
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 600 }}
        width="100%"
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <div style={{ ...styles.header }}>User Profile Confirmation</div>
        <Row>
          {_.map(formData, (value, key) => {
            console.log(formData);
            return (
              <>
                <Col
                  span={10}
                  style={{ minWidth: "30px" }}
                >
                  {key}
                </Col>
                <Col span={1}>:</Col>
                <Col
                  span={10}
                >
                  {value}
                </Col>
              </>
            );
          })}
        </Row>
        {/* <Row>
                    <Col span={11}>
                        <p>Event Name </p>
                    </Col>

                    <Col span={2}>
                        <p> :</p>
                    </Col>

                    <Col span={11}>
                        <div style={{ ...styles.data }}>
                            {userData.event_name}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={11}>
                        <p>Event Date & Time</p>
                    </Col>

                    <Col span={2}>
                        <p> :</p>
                    </Col>

                    <Col span={11}>
                        <div style={{ ...styles.data }}>
                            {userData.event_name}
                        </div>
                    </Col>
                </Row>


                <Col>

                    <Form.Item
                        label="Event Name :"


                    >

                        <Col>
                            <div style={{ ...styles.data }}>
                                {userData.event_name}
                            </div>
                        </Col>
                    </Form.Item>
                    <Form.Item
                        label="Event Date & Time :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.event_date_time}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="ID :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.id}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Email Address :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.email_address}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Phone Number :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.phone_number}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Last Name :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.last_name}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Last Name :"
                    >
                        <div style={{ ...styles.data }}>
                            {userData.first_name}
                        </div>
                    </Form.Item>
                    <Form.Item >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ ...styles.button }}
                            onClick={() => {
                                setModalVisible(false);
                            }}
                        >
                            {t('Confirm')}
                        </Button>
                    </Form.Item>
                {/* </Col> */}

      </Modal>
    </NavAndSideFrame>
  );
};

export default EventCheckIn;
