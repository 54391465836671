import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Modal,
  Button,
  InputNumber,
  Switch,
  DatePicker,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import * as Service from "../../../core/Service";
import TimeSlot from "../TimeSlot";
import moment from "moment";

dayjs.extend(customParseFormat);

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun", "holiday"];

const BookingItemRuleSetModal = (props) => {
  const { t } = useTranslation();
  const { selectedRecord, modalVisible, setModalVisible, handleFormSubmit } =
    props;
  const [ItemInfoForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [switchBool, setSwitchBool] = useState({
    effective_all_time: false,
  });
  const [barWidth, setBarWidth] = useState(null);
  const [barHeight, setBarHeight] = useState(null);
  const [timeSlotList, setTimeSlotList] = useState({});

  const timeBarRef = useRef(null);
  const [itemRulesetList, setItemRulesetList] = useState([]);

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  useEffect(() => {
    ItemInfoForm.resetFields();
    if (
      selectedRecord.booking_item_timeslot_id !== 0 &&
      !_.isUndefined(selectedRecord.booking_item_timeslot_id)
    ) {
      const {
        section_duration,
        max_duration,
        min_duration,
        buffer_before,
        buffer_after,
      } = selectedRecord;

      setSwitchBool({
        effective_all_time: selectedRecord.effective_all_time === 1,
      });
      ItemInfoForm.setFieldsValue({
        ...selectedRecord,
        section_duration: section_duration / 60,
        max_duration: max_duration / 60,
        min_duration: min_duration / 60,
        buffer_before: buffer_before / 60,
        buffer_after: buffer_after / 60,
        effective_start_end: [
          dayjs.unix(selectedRecord.effective_start),
          dayjs.unix(selectedRecord.effective_end),
        ],
      });
    } else {
      setSwitchBool({ effective_all_time: true });
    }
  }, [selectedRecord]);

  const handleWindowResize = (data, newWidth) => {
    const details = _.map(data, (slot) => {
      const clonedObj = _.clone(slot);
      let start = clonedObj.start_time.split(":");
      const fromStart = _.toNumber(start[0]) + _.toNumber(start[1]) / 60;
      clonedObj.x = (newWidth / 24) * fromStart;
      clonedObj.y = 0;
      const startTime = moment(clonedObj.start_time, "HH:mm");
      const endTime = moment(clonedObj.end_time, "HH:mm");
      const duration = moment.duration(endTime.diff(startTime));
      const hours = duration.asMinutes() / 60;
      clonedObj.width = (newWidth / 24) * hours;
      clonedObj.height = 20;
      return clonedObj;
    });
    const bookingSchedule = _.groupBy(details, (slot) =>
      days.indexOf(slot.day)
    );
    console.log({ data, bookingSchedule });
    setTimeSlotList(bookingSchedule);
  };

  const rulesetSubmit = async (id) => {
    setLoading(true);
    try {
      let method = "post";

      let bookingTimeSlot = [];
      _.map(timeSlotList, (timeSlot, i) => {
        _.map(
          timeSlot,
          ({
            start_time,
            end_time,
            booking_item_timeslot_ruleset_id,
            section_price,
          }) => {
            bookingTimeSlot.push({
              day: days[i],
              start_time,
              end_time,
              section_price,
              booking_item_timeslot_ruleset_id,
            });
          }
        );
      });
      const postObj = {
        booking_item_timeslot_id: id,
        timeSlotList: bookingTimeSlot,
      };
      console.log({ postObj });

      let resp = await Service.call(
        method,
        "/api/booking_item/timeslot/ruleset",
        postObj
      );
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      notification.success({
        message: t("success"),
      });
      return getBookingConfigRuleset();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getBookingConfigRuleset = async () => {
    try {
      setLoading(true);
      let url = `/api/booking_item/timeslot/ruleset?booking_item_timeslot_id=${selectedRecord.booking_item_timeslot_id}`;
      let resp = await Service.call("get", url);
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      resp = resp?.data?.itemRulesetList;
      setItemRulesetList(resp);
      handleWindowResize(resp, timeBarRef.current?.offsetWidth);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    setLoading(true);
    try {
      const {
        section_duration,
        max_duration,
        min_duration,
        buffer_before,
        buffer_after,
        effective_start_end,
      } = formData;
      let start_time = _.head(effective_start_end);
      let end_time = _.last(effective_start_end);
      await rulesetSubmit(selectedRecord?.booking_item_timeslot_id);
      await handleFormSubmit({
        ...selectedRecord,
        ...formData,
        section_duration: section_duration * 60,
        max_duration: max_duration * 60,
        min_duration: min_duration * 60,
        buffer_before: buffer_before * 60,
        buffer_after: buffer_after * 60,
        effective_all_time: switchBool.effective_all_time ? 1 : 0,
        effective_start: switchBool.effective_all_time
          ? 0
          : dayjs(start_time).unix(),
        effective_end: switchBool.effective_all_time
          ? 0
          : dayjs(end_time).unix(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={
        selectedRecord.booking_item_price_id === 0
          ? t("add_item")
          : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        {...layout}
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("name")}
              name="name"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <Input maxLength={55} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("remark")}
              name="remark"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("priority")}
              name="priority"
              rules={[{ required: true, message: t("input_required") }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item
              label={t("section_duration")}
              name="section_duration"
              rules={[
                { required: true, message: t("please_input_section_duration") },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("max_duration")}
              name="max_duration"
              rules={[
                { required: true, message: t("please_input_max_duration") },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("min_duration")}
              name="min_duration"
              rules={[
                { required: true, message: t("please_input_min_duration") },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("section_price")}
              name="section_price"
              rules={[
                { required: true, message: t("please_input_section_price") },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("capacity")}
              name="capacity"
              rules={[{ required: true, message: t("please_input_capacity") }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item label={t("buffer_before")} name="buffer_before">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("buffer_after")} name="buffer_after">
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("prebooking_type")}
              name="prebooking_type"
              rules={[
                { required: true, message: t("please_select_booking_type") },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              label={t("effective_all_time")}
              name="effective_all_time"
              key="effective_all_time"
            >
              <Switch
                checked={switchBool.effective_all_time}
                onChange={(bool) => {
                  setSwitchBool({
                    ...switchBool,
                    effective_all_time: bool === true ? 1 : 0,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("effective_start_end")}
              name="effective_start_end"
              key="effective_start_end"
              rules={[
                {
                  required: Boolean(!switchBool.effective_all_time),
                  message: t("please_select_date"),
                },
              ]}
            >
              <RangePicker
                placeholder={[t("start"), t("end")]}
                disabled={switchBool.effective_all_time}
                disabledDate={(current) => {
                  return dayjs().add(-1, "days") >= current;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {selectedRecord.booking_item_timeslot_id > 0 && (
          <TimeSlot
            days={days}
            timeSlotList={timeSlotList}
            setTimeSlotList={setTimeSlotList}
            barWidth={barWidth}
            barHeight={barHeight}
            setBarWidth={setBarWidth}
            setBarHeight={setBarHeight}
            selectedRecord={selectedRecord}
            timeBarRef={timeBarRef}
            getBookingConfigRuleset={getBookingConfigRuleset}
            handleWindowResize={handleWindowResize}
            itemRulesetList={itemRulesetList}
          />
        )}
        {/* <Divider /> */}
        <Row justify="end" style={{ marginTop: 24 }}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BookingItemRuleSetModal;
