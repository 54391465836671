import React, { useEffect, useState } from "react";
import { Divider, notification, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import ProductItemTable from "./ProductItemTable";
import ProductItemModal from "./ProductItemModal";
import useFetch from "../../../hooks/useFetch";
import SearchBar from "../../../components/SearchBar";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "product_item_list";
const openKeys = "product"
const FILTERABLE_FIELDS = [
  "product_name",
  // "product_key",
  // "sku"
];

const ItemInfoList = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const { t } = useTranslation();
  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    route: "/api/product_item/list",
  });
  const [filterDataList, setFilterDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const title = t("product_item_list");

  useEffect(() => {
    setFilterDataList(dataList);
  }, [dataList]);

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let method = submitRecord.product_item_id === 0 ? "put" : "patch";
      let resp = await Service.call(method, "/api/product_item", submitRecord);
      if (resp.status < 1) {
        return notification.error({
          message: t("fail"),
          description: t(`${resp.errorCode}`),
        });
      }
      notification.success({
        message: t("success"),
      });
      setModalVisible(false);
      setRefresh(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecord = async (product_item_id) => {
    setLoading(true);
    let postData = {
      product_item_id,
    };
    let submit = await Service.call(
      "post",
      "/api/product_item/delete",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      setLoading(false);
      setRefresh(true);
      setModalVisible(false);
    }
  };

  const handleIsActiveChange = async (product_item_id, value) => {
    setLoading(true);
    let postData = {
      product_item_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/product_item/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(`${submit.errorCode}`),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      setLoading(false);
      setRefresh(true);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ product_item_id: 0 });
          setModalVisible(true);
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />
      <Row align="middle">
        <Col>
          <span>{`${t("search_keyword")}：`}</span>
        </Col>
        <Col>
          <SearchBar
            originalDataList={dataList}
            filterDataList={dataList}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
          />
        </Col>
      </Row>
      <Divider />
      <ProductItemTable
        dataObj={filterDataList}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        deleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(product_item_id, value) => {
          handleIsActiveChange(product_item_id, value);
        }}
        loading={loading || refresh}
      />
      <ProductItemModal
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  );
};

export default ItemInfoList;
