import React, { useState, useEffect } from "react";
import { Button, Layout, Popconfirm, Table, Tag, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import '../Banner/node_modules/antd/dist/antd.css';
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BlogPostListTable = (props) => {
  const { t } = useTranslation();
  const involvedModelName = "company";
  const title = "Blog Post";
  const tableIDName = "blog_post_id";
  const app = useSelector((state) => state.app);
  const {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    handleDeleteRecord,
    deleteRecord,
  } = props;
  const { STATIC_SERVER_URL } = app.config;

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [keyByIdCategoryList, setKeyByIdCategoryList] = useState({
    blogPostCategory: {},
    newsCategory: {}
  });

  useEffect(() => {
    setDataList(dataObj);
    setKeyByIdCategoryList({
      blogPostCategory: _.keyBy(
        dataObj.blogPostCategoryArr,
        "blog_post_category_id"
      ),
      newsCategory: _.keyBy(
        dataObj.newsCategoryArr,
        "news_category_id"
      ),
    });
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              {/* <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    isActiveChangeCallBack(record.blog_post_id, is_active)
                  }}
                />
              </Tooltip> */}
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => handleDeleteRecord(record.blog_post_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
      {
        title: t("title"),
        dataIndex: "title",
        sorter: (a, b) => a.title - b.title,
      },
      {
        title: t("type_name"),
        dataIndex: "type_name",
        sorter: (a, b) => a.type_name.localeCompare(b.type_name),
        render: (value) => displayTypeName(value),
      },
      {
        title: t("post_date"),
        dataIndex: "post_date",
        sorter: (a, b) => a.post_date - b.post_date,
        render: (post_date) => {
          return moment.unix(post_date).format("YYYY-MM-DD");
        },
      },
      {
        title: t("category_name"),
        dataIndex: "type_category_id",
        render: (value, record) => displayCategoryName(record),
      },
      {
        title: t("banner"),
        dataIndex: "banner",
        render: (value, record, key) => {
          if (value !== null && value !== "") {
            return (
              <div>
                <img
                  src={`${STATIC_SERVER_URL}/${value}`}
                  width={100}
                  height={100}
                  alt={value}
                />
              </div>
            );
          }
          return "";
        },
      },
      {
        title: t("is_active"),
        dataIndex: "is_active",
        sorter: (a, b) => a.is_active - b.is_active,
        render: (value) => displayIsActive(value),
      },
    ];
    return columns;
  };

  const displayTypeName = (type_name) => {
    let displayStr = "";
    let tagColor = "green";
    switch (type_name) {
      case "blog":
        displayStr = t("blog");
        tagColor = "green";
        break;
      case "facilities":
        displayStr = t("facilities");
        tagColor = "blue";
        break;
      case "news":
        displayStr = t("news");
        tagColor = "#9A9A9A";
        break;
      case "promotion":
        displayStr = t("promotion");
        tagColor = "#9E8677";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayCategoryName = (record) => {
    let displayStr = "";
    switch (record.type_name) {
      case "blog":
        displayStr =
          keyByIdCategoryList.blogPostCategory[record.type_category_id]
            ?.category_name || "";
        break;
      case "facilities":
        displayStr = t("facilities");
        break;
      case "news":
        displayStr = t("news");
        displayStr =
        keyByIdCategoryList.newsCategory[record.type_category_id]
          ?.category_name || "";
        break;
      case "promotion":
        displayStr = t("promotion");
        break;
      default:
        displayStr = "Error";
        break;
    }
    return displayStr;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList.blogPostArr}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize);
          }
        }}
        loading={loading}
      />
    </Layout>
  );
};

export default BlogPostListTable;
