import React, { useEffect } from "react";
import _ from "lodash";
import { Button, Form, notification, Row, Col, Card, Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const {Option} = Select;

const UserConfigForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { initialValues, isAdmin } = props;
  const id = initialValues.company_user_id;
  const { key } = queryString.parse(location.search);
  const history = useHistory();

  const [userConfigForm] = Form.useForm();

  useEffect(() => {
    getUserConfig();
  }, [initialValues]);

  const getUserConfig = async () => {
    userConfigForm.resetFields();
    try {
      let resp = await Service.call("get", `/api/company/user/config/${id}`);
      if (!resp || resp.status !== 1) {
        return notification.error({message: t("error"), description: `${resp.errorMessage}`});
      }
      let {companyUserConfigRc} = resp.data;
      userConfigForm.setFieldsValue(companyUserConfigRc);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (formData) => {
    let patchData = {
      ...formData,
      company_user_id: id,
    };
    if (id > 0) {
      let response = await Service.call("patch", "/api/company/user/config", patchData);
      if (response.status === -1) {
        notification.error({
          message: t("error"),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });

      if (id === 0) {
        history.push("/company/user/list");
      } else {
        history.push(`/company/user/list?key=${key}`);
      }
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={userConfigForm}
            name="userConfigForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            <Form.Item
              label={t("payment_type")}
              name="payment_type"
            >
              <Select>
                <Option value={0}>{t("pay_immediately")}</Option>
                <Option value={1}>{t("pay_monthly")}</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!isAdmin}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserConfigForm;
