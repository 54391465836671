import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  notification,
  Row,
  Col,
  Card,
} from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const UserAcResetPasswordForm = (props) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const id = initialValues.company_user_id;

  const [loading, setLoading] = useState(false);

  const [UserResetPWForm] = Form.useForm();

  useEffect(() => {
    UserResetPWForm.resetFields();
  }, []);

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      let patchData = { ...formData, company_user_id: id };
      // Check password
      if (!validatePassword(formData.password)) {
        return;
      }
      if (id > 0) {
        let response = await Service.call(
          "patch",
          `/api/company/user/password`,
          patchData
        );
        // console.log("response>>", response)
        if (response.status === -1) {
          notification.error({
            message: t(`error`),
            description: t(response.errorCode),
          });
          return;
        }
        notification.success({
          message: t("success"),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      notification.error({
        message: t("error"),
        description: t("password_length_too_short")
      });
      return false;
    }
    if (password.search(/[a-z]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_character")
      });
      return false;
    }
    if (password.search(/[0-9]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_number")
      });
      return false;
    }
    return true;
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserResetPWForm}
            name="UserResetPWForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label={t("edit_password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('input_required'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={t("confirm_password")}
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: t('input_required'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('password_not_match'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserAcResetPasswordForm;
