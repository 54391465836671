import {
  Button, Form, Input, Modal, Space, Tabs, message
} from "antd";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

const PaymentMethodModal = (props) => {
  const [form] = Form.useForm();
  // Get param from props
  const {
    modalVisible,
    setModalVisible,
    dataList,
    setDataList
  } = props;


  // Functions
  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (formObj) => {
    let clonePostObj = _.clone(formObj);

    let newDataList = _.clone(dataList);
    newDataList.push({
      payment_method_id: newDataList.length + 1,
      payment_method_type: clonePostObj.title,
      ctime: moment().unix()
    });
    setDataList(newDataList);
    setModalVisible(false);
    message.success("success");
  };

  return (
    <Modal
      title="Add"
      visible={modalVisible}
      onCancel={closeModal}
      footer={false}
      bodyStyle={{padding: "12px 24px"}}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Payment Method"
          name="title"
          rules={[{
            required: true,
            message: "Please fill title"
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
            >
              submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};


export default PaymentMethodModal;
