import React, { useState, useEffect, useRef } from "react";
import {
  Divider,
  notification,
  Modal,
  Row,
  Col,
  DatePicker,
  Select,
  Tooltip,
  Button,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import OrderInfoTable from "./OrderInfoTable";
import useFetch from "../../../hooks/useFetch";
import OrderApprovementDocument from "./OrderApprovementDocument";
import * as Main from "../../../core/Main";
import * as Service from "../../../core/Service";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "order_list";
const openKeys = "sale_and_operations";

const { RangePicker } = DatePicker;
const { Option } = Select;

const PAGINATION_SIZE = 10;

const OrderInfoList = (props) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedField, setSelectedField] = useState({
    user: null,
    date: [
      moment().subtract(1, "M").startOf("d"),
      moment(),
    ],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    route: "/api/order/list",
    whereClause: {
      start_time: _.head(selectedField.date).unix(),
      end_time: _.last(selectedField.date).unix(),
      offset: (currentPage - 1) * PAGINATION_SIZE,
      company_user_id: selectedField.user,
    }
  });

  const { t } = useTranslation();

  const title = t("order_list");

  const generateEventOrderReport = async (
    start_time, end_time, company_user_id = 0
  ) => {
    let queryParams = {
      start_time,
      end_time,
      company_user_id
    };

    try {
      let resp = await Service.call("get", "/api/order/event_order_list", queryParams);
      if (resp.status !== 1) {
        message.error(resp.errorMessage);
        return;
      }
      if (resp.status === 1) {
        let eventOrderList = resp.data.eventOrderList;
        let reportFileName = moment().unix().toString() + "-order-report";
        Main.exportCsvFile(
          eventOrderList,
          [[]],
          reportFileName
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[50, 20]}>
        <Col>
          <span>
            <Tooltip title={t("download_report")}>
              <Button
                style={{ marginRight: 8, color: "#000000" }}
                type="primary"
                onClick={() => {
                  let start_time = _.head(selectedField.date).unix();
                  let end_time = _.last(selectedField.date).unix();
                  let company_user_id = 0;
                  if (selectedField.user !== undefined && selectedField.user !== null) {
                    company_user_id = selectedField.user;
                  }
                  // click to generate order event report
                  generateEventOrderReport(start_time, end_time, company_user_id);
                }}
              >
                Transaction Report
              </Button>
            </Tooltip>
          </span>
        </Col>
      </Row>
      <Row gutter={[50, 20]}>
        <Col>
          <span>
            <Tooltip title={t("download_report")}>
              <Button
                style={{ marginRight: 8, color: "#000000" }}
                type="primary"
                onClick={() => {
                  let start_time = _.head(selectedField.date).unix();
                  let end_time = _.last(selectedField.date).unix();
                  let company_user_id = 0;
                  if (selectedField.user !== undefined && selectedField.user !== null) {
                    company_user_id = selectedField.user;
                  }
                  // * Remark: dummy button only, same as transaction report
                  // click to generate order event report
                  generateEventOrderReport(start_time, end_time, company_user_id);
                }}
              >
                Lead Report
              </Button>
            </Tooltip>
          </span>
        </Col>
      </Row>
      <Row gutter={[50, 20]}>
        <Col>
          <span>{`${t("date")}：`}</span>
          <RangePicker
            allowClear={false}
            allowEmpty={false}
            placeholder={[t("start"), t("end")]}
            value={selectedField.date}
            onChange={(dates) => {
              setSelectedField({
                ...selectedField,
                date: dates,
              });
              setCurrentPage(1);
              setRefresh(true);
            }}
          />
        </Col>
        <Col>
          <span>{`${t("company_user")}：`}</span>
          <Select
            allowClear
            value={selectedField.user}
            style={{ width: 400 }}
            onChange={(value) => {
              setSelectedField({
                ...selectedField,
                user: value,
              });
              setCurrentPage(1);
              setRefresh(true);
            }}
          >
            {_.map(dataList.companyUserList, (eachUser) => {
              let {
                company_user_id,
                nickname,
                first_name,
                last_name,
              } = eachUser;
              return (
                <Option key={eachUser.company_user_id} value={eachUser.company_user_id}>
                  {`(${company_user_id}) ${first_name} ${last_name} (${nickname})`}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Divider />
      <OrderInfoTable
        dataObj={dataList}
        adminChangeData={() => {
          setRefresh(true);
        }}
        setLoading={setLoading}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading || refresh}
        currentPage={currentPage}
        setCurrentPage={(page) => {
          setCurrentPage(page);
          setRefresh(true);
        }}
        pagination_size={PAGINATION_SIZE}
      />
      <Modal
        title={<div style={{ margin: "0 0 0 40px", fontWeight: "bolder" }}>Order List</div>}
        footer={null}
        visible={modalVisible}
        bodyStyle={{margin: "0 30px"}}
        width={1200}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRecord({});
        }}
        destroyOnClose
      >
        <OrderApprovementDocument
          selectedRecord={selectedRecord}
          openModal={(value) => {
            setModalVisible(value);
          }}
          modalVisible={modalVisible}
        />
      </Modal>
    </NavAndSideFrame>
  );
};

export default OrderInfoList;
