import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Layout,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Select,
  notification,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import * as Service from "../../../core/Service";
import dayjs from "dayjs";

const { Option } = Select;

const OrderInfoTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "order_id";
  let {
    dataObj,
    setModalVisible,
    setSelectedRecord,
    loading,
    setLoading,
    adminChangeData,
    currentPage,
    setCurrentPage,
    pagination_size,
  } = props;

  const tagOption = {
    1: {
      color: "blue",
      displayStr: "Booking"
    },
    2: {
      color: "green",
      displayStr: "Product"
    },
    3: {
      color: "yellow",
      displayStr: "Event"

    }
  }

  const keyById_userList = useMemo(() => {
    let { companyUserList, companyAdminList } = dataObj;
    return {
      companyUser: _.keyBy(companyUserList, "company_user_id"),
      companyAdmin: _.keyBy(companyAdminList, "company_admin_id"),
    }
  }, [dataObj]);
  const keyById_orderInfo = useMemo(() => {
    let { orderList } = dataObj;
    return _.keyBy(orderList, "order_id")
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "order_id",
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("order_info")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: t("order_id"),
        dataIndex: "order_id",
        sorter: (a, b) => a.order_id - b.order_id,
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value, record) => displaySelectBar(record.order_id, value),
      },
      {
        title: t("order_type"),
        dataIndex: "order_type",
        filterSearch: true,
        filters: _.map(tagOption, (value, key) => {
          return { value: key, text: value.displayStr }
        }),
        onFilter: (value, record) => {
          return record.order_type.includes(_.toInteger(value))
        },

        render: (value) => {
          const invalidColor = "red"
          const invalidWord = "invalid"
          if (_.isEmpty(value)) {
            return <Tag color={invalidColor}>{t(invalidWord)}</Tag>
          }
          return (
            <>
              {_.map(value, (rc) => {
                const tagRc = tagOption[rc];
                if (!tagRc) {
                  return <Tag key={rc} color={invalidColor}>{t(invalidWord)}</Tag>
                }
                const { color, displayStr } = tagRc
                return (
                  <Tag
                    key={rc}
                    color={color}
                  >
                    {t(displayStr)}
                  </Tag>
                )
              })
              }
            </>
          );

        },

      },
      {
        title: t("user"),
        dataIndex: "company_user_id",
        render: (value) => {
          return keyById_userList.companyUser[value]
            ? `${keyById_userList.companyUser[value].first_name} ${keyById_userList.companyUser[value].last_name}`
            : "-";
        },
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("final_price"),
        dataIndex: "final_price",
        sorter: (a, b) => a.final_price - b.final_price,
      },
      {
        title: t("pick_up_date"),
        dataIndex: "pick_up_date",
        align: "center",
        render: (value) => {
          if (!value) {
            return "-"
          }
          return dayjs.unix(value).format("YYYY-MM-DD HH:mm")
        },
      },
      {
        title: t("order_key"),
        dataIndex: "order_key",
        sorter: (a, b) => a.order_key.localeCompare(b.order_key),
      },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayCompanyAdmin = (company_admin_id) => {
    let name = "-";
    let adminRc = keyById_userList.companyAdmin[company_admin_id];
    if (!_.isEmpty(adminRc)) {
      let { first_name, last_name } = adminRc;
      name = `${first_name} ${last_name}`;
    }
    return name;
  };

  const displaySelectBar = (order_id, status) => {
    let component;
    let single = false;
    switch (status) {
      case "placed":
        component = (
          <>
            <Option value="placed" disabled={true}>
              {t("placed")}
            </Option>
            <Option value="cancelled">{t("cancelled")}</Option>
          </>
        );
        break;
      case "pending":
        component = (
          <>
            <Option value="pending" disabled={true}>
              {t("pending")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
            <Option value="cancelled">{t("cancelled")}</Option>
          </>
        );
        break;
      case "pending_approve":
        component = (
          <>
            <Option value="pending_approve" disabled={true}>
              {t("pending_approve")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
          </>
        );
        break;
      case "payment_confirmed":
        component = (
          <>
            <Option value="payment_confirmed" disabled={true}>
              {t("payment_confirmed")}
            </Option>
            <Option value="payment_refund">{t("payment_refund/void")}</Option>
          </>
        );
        break;
      case "payment_failed":
        component = (
          <>
            <Option value="payment_failed" disabled={true}>
              {t("payment_failed")}
            </Option>
            <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
          </>
        );
        break;
      default:
        single = true;
        component = (
          <>
            <Option value={`${status}`} disabled={true}>
              {t(`${status}`)}
            </Option>
          </>
        );
        break;
    }
    let orderRc = keyById_orderInfo[order_id];
    return (
      <Select
        disabled={single}
        style={{ width: 200 }}
        value={orderRc?.status}
        onChange={async (value) => updateOrderStatus(order_id, value)}
      >
        {component}
      </Select>
    );
  };

  const updateOrderStatus = async (order_id, status) => {
    setLoading(true);
    try {
      let resp = await Service.call("patch", "/api/order/status", {
        order_id,
        status,
      });
      if (!resp || resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: resp.errorMessage,
        });
      }
      notification.success({
        message: t("success"),
      });
      return adminChangeData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    switch (value) {
      case "placed":
        displayStr = t("placed");
        tagColor = "black";
        break;
      case "pending":
        displayStr = t("pending");
        tagColor = "brown";
        break;
      case "pending_approve":
        displayStr = t("pending_approve");
        tagColor = "brown";
        break;
      case "payment_confirmed":
        displayStr = t("payment_confirmed");
        tagColor = "green";
        break;
      case "payment_failed":
        displayStr = t("payment_failed");
        tagColor = "red";
        break;
      case "expired":
        displayStr = t("expired");
        tagColor = "gold";
        break;
      case "payment_refund":
        displayStr = t("payment_refund");
        tagColor = "orange";
        break;
      case "partial_payment_refund":
        displayStr = t("partial_payment_refund");
        tagColor = "orange";
        break;
      case "cancelled":
        displayStr = t("cancelled");
        tagColor = "orange";
        break;
      case "voided":
        displayStr = t("voided");
        tagColor = "orange";
        break;
      case "cancel_after_payment":
        displayStr = t("cancel_after_payment");
        tagColor = "orange";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataObj.orderList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{
          pageSize: pagination_size,
          current: currentPage,
          onChange: setCurrentPage,
          total: dataObj.totalLength,
        }}
        loading={loading}
        locale={{
          triggerDesc: t("sort_descend"),
          triggerAsc: t("sort_ascend"),
          cancelSort: t("cancel_sort"),
        }}
      />
    </Layout>
  );
};
export default OrderInfoTable;
