import React, { useState } from "react";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";

import NavAndSideFrame from "../../components/NavAndSideFrame";
import CompanyUserTokenLog from "./CompanyUserTokenLog";

const { TabPane } = Tabs;
const selectedKey = "token_record";
const openKeys = "sale_and_operations"

const CompanyTokenLog = () => {
  const { t } = useTranslation();

  const [onFocus, setOnFocus] = useState();

  return (
    <NavAndSideFrame title={`${t("token_log")}`} selectedKey={selectedKey} openKeys={openKeys}>
      <Tabs
        type="line"
        defaultActiveKey={t("company_user")}
        onTabClick={(value) => {
          setOnFocus(value);
        }}
      >
        <TabPane tab={t("company_user")} key="company_user">
          <CompanyUserTokenLog onFocus={onFocus} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  );
};

export default CompanyTokenLog;
