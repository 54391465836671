import * as types from '../actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  auth: false,
  config: {
    STATIC_SERVER_URL: null,
  },
  loading: false,
  company: {},
  admin: {},
  company_admin: {},
  company_user: {},
  user: {},
  origin: '',
  sidebarCollapse: true,
  timeZoneOffset: 8,
  isAdmin: false,
  isCompanyUser: false
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CONFIG: {
        return { ...state, config: action.data };
      }
      case types.SET_AUTH:
        state = { ...state, auth: action.data }
        return state;
      case types.SET_LOADING:
        state = { ...state, loading: action.data }
        return state;
      case types.SET_COMPANY:
        state = { ...state, company: action.data }
        return state;
      case types.SET_IS_ADMIN:
        state = { ...state, isAdmin: action.data }
        return state;
      case types.SET_IS_COMPANY_USER:
        state = { ...state, isCompanyUser: action.data }
        return state;
      case types.SET_TIME_ZONE_OFFSET:
        state = { ...state, timeZoneOffset: action.data }
        return state;
      case types.SET_SIDER:
        if (action.data !== undefined) {
          state = { ...state, sidebarCollapse: action.data }
        } else {
          state = { ...state, sidebarCollapse: !state.sidebarCollapse }
        }
        return state;
      case types.SET_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            admin: initialState.admin,
          };
        } else {
          state = { ...state, admin: action.data };
        }
        return state;
      case types.SET_COMPANY_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            company_admin: initialState.company_admin,
          };
        } else {
          state = { ...state, company_admin: action.data };
        }
        return state;
      case types.SET_COMPANY_USER:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            company_user: initialState.company_user,
          };
        } else {
          state = { ...state, company_user: action.data };
        }
        return state;
      case types.SET_USER:
        if (!action.data || Object.keys(action.data).length === 0) {
          state = {
            ...state,
            user: initialState.user,
          };
        } else {
          state = { ...state, user: action.data };
        }
        return state;
      case types.SET_ORIGIN:
        state = { ...state, origin: action.data }
        return state;
      default:
        return state;
    }
  }
};

export default appReducer;
