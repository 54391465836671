import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Modal,
  notification,
  Table,
  Popconfirm,
  Button,
  Switch,
  InputNumber,
  Select,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import * as Service from "../../../core/Service";
import MediaLibraryComponent from "../../../components/MediaLibraryComponent";
import CategoryTreeSelect from "../../../components/CategoryTreeSelect";
import TinymceWrapper from "../../../components/TinymceWrapper";
import {TranslationFormItemInput, TranslationFormItemEditor} from "../../../components/TranslationWrapper";
import LanguageList from "../../../data/LanguageList.json";

const { Option } = Select;


const { TextArea } = Input;

const ItemInfoModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord, modalVisible, setModalVisible, handleFormSubmit
  } = props;
  const config = useSelector((state) => state.app.config);
  const [ItemInfoForm] = Form.useForm();

  const [fileinfo, setFileinfo] = useState([]);
  const [defaultImg, setDefaultImg] = useState(null);
  let sorting = useRef({});
  const [sortState, setSortState] = useState({});
  const [loading, setLoading] = useState(false);
  const [itemDesc, setItemDesc] = useState(null);
  const [switchItem, setSwitchItem] = useState({
    ignore_stock: 1,
    has_vip_discount: 0,
  });

  const [shortDesc, setShortDesc] = useState("");

  useEffect(() => {
    setItemDesc("");
    setShortDesc("");
    ItemInfoForm.resetFields();
    if (
      selectedRecord.product_item_id !== 0
      && !_.isUndefined(selectedRecord.product_item_id)
    ) {
      const translation_key = getTranslationKey(selectedRecord)
      setItemDesc(selectedRecord.item_desc);
      setShortDesc({
        default: selectedRecord.short_desc,
        ...translation_key.short_desc
      });
      setSwitchItem({
        ignore_stock: selectedRecord.ignore_stock,
        has_vip_discount: selectedRecord.has_vip_discount,
      });
      getProductItemRef();
      getItemImage();
    }
  }, [selectedRecord]);

  const getTranslationKey = (data) => {
    const translation_key = {
      short_desc: {},
    }
    _.map(LanguageList, (value, key) => {
      // for editor
      _.assign(translation_key.short_desc, { [key]: data[`short_desc_${key}`] })
    })

    return translation_key
  }

  const getProductItemRef = async () => {
    try {
      let url = `/api/product_category_ref/${selectedRecord.product_item_id}`;
      let resp = await Service.call("get", url);
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(`${resp.errorCode}`),
        });
      }
      ItemInfoForm.setFieldsValue({
        ...selectedRecord,
        category_ids: _.map(resp.data, "product_category_id"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getItemImage = async () => {
    setLoading(true);
    try {
      let resp = await Service.call(
        "get",
        `/api/product_item/image/${selectedRecord.product_item_id}`
      );
      setFileinfo(resp.data);
      let [defaultImage] = _.filter(resp.data, { is_default: 1 });
      if (defaultImage) {
        setDefaultImg(defaultImage.product_item_image_id);
      }

      let sort = {};
      _.each(resp.data, (eachImage) => {
        sort[eachImage.product_item_image_id] = eachImage.sorting;
      });
      sorting.current = sort;
      setSortState(sorting.current);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const itemImageColumns = [
    {
      title: t("image"),
      render: (value, record) => {
        let path = `${config.STATIC_SERVER_URL}/${record.filepath}`;
        return <img src={path} width="100px" height="100px" alt="item" />;
      },
    },
    {
      title: t("is_default"),
      dataIndex: "is_default",
      render: (value, record) => (
        <Switch
          checked={defaultImg === record.product_item_image_id}
          onChange={(event) => {
            if (event) {
              setDefaultImg(record.product_item_image_id);
            } else {
              setDefaultImg(null);
            }
          }}
        />
      ),
    },
    {
      title: t("sorting"),
      dataIndex: "sorting",
      render: (value, record) => (
        <Input
          value={sortState[record.product_item_image_id]}
          onChange={(e) => {
            setSortState({
              ...sortState,
              [record.product_item_image_id]: _.toInteger(e.target.value),
            });
          }}
        />
      ),
    },
    {
      title: t("delete"),
      dataIndex: "operation",
      render: (value, record) => (
        <Popconfirm
          title="confirm_delete"
          onConfirm={() => onDeleteImage(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ color: "#AA0000" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const onDeleteImage = async (record) => {
    setLoading(true);
    try {
      await Service.call("post", "/api/product_item_image", record);
      await getItemImage();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (formData) => {
    setLoading(true);
    let languageParam = {};
    _.map(LanguageList, (val, key) => {
      languageParam[`short_desc_${key}`] = shortDesc[key];
    })
    try {
      await handleFormSubmit({
        ...formData,
        place_of_origin: "",
        reward_point: 0,
        short_desc: shortDesc.default,
        short_desc_2: "",
        currency: "hkd",
        product_item_id: selectedRecord.product_item_id || 0,
        product_category_id_list: formData.category_ids,
        is_default: defaultImg,
        image_sorting: sortState,
        item_desc: itemDesc || "",
        ignore_stock: switchItem.ignore_stock,
        has_vip_discount: switchItem.has_vip_discount,
        stock_number: formData.ignore_stock === 1 ? 0 : formData.stock_number,
        ...languageParam
      });
    } catch (error) {
      console.error(error);
    } finally {
      
      setLoading(false);
    }
  };

  const renderInputFormItem = (itemArray) => {
    // Each itemArray => {span, form_item, maxLength}
    return _.map(itemArray, (eachItem) => {
      let { span, form_item, maxLength } = eachItem;
      return (
        <Col span={span}>
          <Form.Item
            label={t(`${form_item}`)}
            name={form_item}
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input maxLength={maxLength} />
          </Form.Item>
        </Col>
      );
    });
  };

  const renderInputNumberFormItem = (itemArray) => {
    // Each itemArray => {span, form_item}
    return _.map(itemArray, (eachItem) => {
      let { span, form_item } = eachItem;
      return (
        <Col span={span}>
          <Form.Item
            label={t(`${form_item}`)}
            name={form_item}
            rules={[{ required: true, message: t("input_required") }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      );
    });
  };

  return (
    <Modal
      title={selectedRecord.item_id === 0 ? t("add_item") : t("edit_record")}
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Row gutter={[2, 2]}>
          <TranslationFormItemInput
            name="product_name"
            label={`${t('common:product_name')} ${t('common:title')}`}
            header={`${t('common:product_name')} ${t('common:translation')}`}
          />
          <TranslationFormItemEditor
            defaultName="default"
            header={`${t('common:short_desc')} ${t('common:translation')}`}
            initialValue={shortDesc}
            label={t('common:short_desc')}
            onChange={(value, key) => {
              setShortDesc({
                ...shortDesc,
                [key]: value
              });
            }}
          />
              {renderInputFormItem([
            // {
            //   span: 10,
            //   form_item: "product_name",
            //   // maxLength: 55,
            // },
            {
              span: 10,
              form_item: "sku",
              maxLength: 50,
            },
            // {
            //   span: 7,
            //   form_item: "place_of_origin",
            //   maxLength: 50,
            // },
          ])}
        </Row>
        <Row>
          {renderInputNumberFormItem([
            // {
            //   span: 12,
            //   form_item: "original_price",
            // },
            {
              span: 12,
              form_item: "price",
            },
            {
              span: 12,
              form_item: "original_price",
            },
            {
              span: 12,
              form_item: "preorder_day",
            },
            {
              span: 12,
              form_item: "sorting",
            },
            // {
            //   span: 6,
            //   form_item: "reward_point",
            // },
          ])}
          {/* {renderInputFormItem([
            {
              span: 6,
              form_item: "currency",
            },
          ])} */}
        </Row>
        <Row>
          <Form.Item label={t("is_hot")} name="is_hot">
            <Select style={{ width: 120 }}>
              <Option value={1}>{t('true')}</Option>
              <Option value={0}>{t('false')}</Option>
            </Select>
          </Form.Item>
        </Row>
        <CategoryTreeSelect
          edit={!_.isEmpty(selectedRecord)}
          id={selectedRecord.product_category_id}
          action="/api/product_category/list"
          primary_id="product_category_id"
          parent_id="parent_product_category_id"
        />

        <Row>
          <Form.Item label={t("desc")}>
            <TinymceWrapper
              initialValue={itemDesc}
              onChange={(value) => {
                setItemDesc(value);
              }}
            />
          </Form.Item>
        </Row>
        {/* <Row>
          <Col span={4}>
            <Form.Item
              label={t("ignore_stock")}
              rules={[{ required: true, message: t("input_required") }]}
            >
              <Switch
                checked={switchItem.ignore_stock}
                onChange={(value) => {
                  setSwitchItem((prevState) => ({
                    ...prevState,
                    ignore_stock: value === true ? 1 : 0,
                  }));
                }}
              />
            </Form.Item>
          </Col>
          {switchItem.ignore_stock === 0
            && renderInputNumberFormItem([
              {
                span: 4,
                form_item: "stock_number",
              },
            ])}
          <Col span={4}>
            <Form.Item
              label={t("has_vip_discount")}
              name="has_vip_discount"
            >
              <Switch
                checked={switchItem.has_vip_discount}
                onChange={(value) => {
                  setSwitchItem((prevState) => ({
                    ...prevState,
                    has_vip_discount: value === true ? 1 : 0,
                  }));
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Divider />
        {/* <Form.Item
          label={t("short_desc")}
          name="short_desc"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("short_desc_2")}
          name="short_desc_2"
          rules={[{ required: true, message: t("input_required") }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item label={t("desc")}>
          <TinymceWrapper
            initialValue={itemDesc}
            onChange={(value) => {
              setItemDesc(value);
            }}
          />
        </Form.Item> */}
        {selectedRecord.product_item_id !== 0 && (
          <>
            <Form.Item label={t("banner")}>
              <MediaLibraryComponent
                showImage={false}
                selectCallback={async (mediaObj) => {
                  try {
                    setLoading(true);
                    await Service.call("put", "/api/product_item_image", {
                      ...mediaObj,
                      product_item_id: selectedRecord.product_item_id,
                    });
                    await getItemImage();
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </Form.Item>
            <Table
              loading={loading}
              columns={itemImageColumns}
              dataSource={fileinfo}
              pagination={false}
            />
          </>
        )}
        {/* <Divider /> */}
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ItemInfoModal;
