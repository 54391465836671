import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  message, Table, Spin, PageHeader
} from "antd";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";


const CompanyUserTransactionRecord = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { company_user_id } = queryString.parse(location.search);
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  // side bar
  const selectedKey = "user_list";
  const openKeys = "user";

  // table row key
  const tableIDName = "order_id";

  // constants
  const PAGE_SIZE = 10;

  useEffect(() => {
    getTransactionRecord(1);
  }, []);

  const getTransactionRecord = async (curPageNum) => {
    try {
      setLoading(true);
      let requestQuery = {
        company_user_id: _.toInteger(company_user_id),
        status: "payment_confirmed",
        page_size: PAGE_SIZE,
        page_number: curPageNum,
      };

      let resp = await Service.call("get", "/api/payment/order", requestQuery);
      if (resp.status !== 1) {
        message.error(resp.errorMessage);
      }
      if (resp.status === 1) {
        setDataList(resp.data.orderList);
        setDataTotal(resp.data.totalLength);
      }
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("order_id"),
        dataIndex: "order_id"
      },
      {
        title: t("order_key"),
        dataIndex: "order_key",
      },
      {
        title: t("status"),
        dataIndex: "status",
      },
      {
        title: t("final_price"),
        dataIndex: "final_price",
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  // antd function
  const handlePaginationOnChange = (page, pageSize) => {
    getTransactionRecord(page);
  };

  return (
    <NavAndSideFrame
      {...props}
      title="Transaction Record"
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <PageHeader
        onBack={() => history.push("/company/user/list")}
        title="Go Back"
      />
      <Spin size="large" spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{
            pageSize: PAGE_SIZE,
            onChange: handlePaginationOnChange,
            total: dataTotal,
            showSizeChanger: false,
          }}
        />
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyUserTransactionRecord;
