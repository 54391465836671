import React, { useState, useEffect } from "react";
import {
  Row, Col, Modal, Table, Typography, Tooltip, Button, Form, DatePicker
} from "antd";
import {
  SearchOutlined,
  UpOutlined,
  EditOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment";
import * as Service from "../../../core/Service";

// import * as Helper from "../../core/helper/RenderDisplay";
import NotificationModal from "./NotificationModal";

const NotificationTable = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const tableIDName = "notification_queue_id";
  // get parms from props
  const {
    loading,
    setLoading,
    setModalVisible,
    setSelectedRecord,
    forceRefresh
  } = props;

  useEffect(() => {
    getNotificationList();
  }, [forceRefresh]);

  const getNotificationList = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/notification/queue/list");
      if (resp.status === 1) {
        setDataList(resp.data);
      }
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: "notification_queue_id",
        render: (value, record) => {
          return (
            <Tooltip title={t("view")}>
              <Button
                shape="circle"
                style={{ marginRight: 8, color: "#000000" }}
                icon={<UpOutlined />}
                onClick={() => {
                  setSelectedRecord(record);
                  setModalVisible(true);
                }}
              />
            </Tooltip>
          );
        }
      },
      {
        title: "Title",
        key: "title",
        dataIndex: "title",
        render: (value) => t(value),
      },
      {
        title: "Content",
        key: "content",
        dataIndex: "content",
        width: 500,
        render: (value, data) => (
          <Row align="middle" wrap="false" gutter={[5, 0]}>
            <Col flex="none">
              <SearchOutlined
                onClick={() => {
                  Modal.info({
                    title: t(data.title),
                    width: 800,
                    content: t(value),
                    maskClosable: true,
                  });
                }}
              />
            </Col>
            <Col flex="auto">
              <Typography.Paragraph style={{ margin: 0, maxWidth: 460 }} ellipsis={{ rows: 2 }}>
                {t(value)}
              </Typography.Paragraph>
            </Col>
          </Row>
        ),
      },
      {
        title: t("ctime"),
        key: "ctime",
        dataIndex: "ctime",
        width: 200,
        render: (value) => (moment.unix(value).format("YYYY-MM-DD HH:mm")),
      },
    ];
    return columns;
  };

  return (
    <>
      <Table
        rowKey={tableIDName}
        scrollSize="max-content"
        columns={setTableHeader()}
        dataSource={dataList}
        loading={loading}
      />
    </>
  );
};

export default NotificationTable;
