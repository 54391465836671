import React, { Component, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Divider,
    Form,
    Input,
    Tag,
    Row,
    Col,
    Card,
    message,
    notification
} from "antd";

import { QrReader } from 'react-qr-reader';

const EventQRReader = (props) => {
    const [data, setData] = useState('No result');
    const {onScan , scanDelay} = props

    return (
        <div className="container" style={{width: "100%"}}>
            <div className="QRtitle" style={{margin: "20px 0"}}>Scan to Check-in</div>
            {/* <img
                    alt=""
                    src={QRcodeboarder2}
                    style={{
                        width: "100%",
                    }} /> */}
            <div style={{
                width: "100%",
                // backgroundImage: `url(${QRcodeboarder3})`,
                marginBottom: "50px",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                
            }}>

                <QrReader
                    videoContainerStyle={{
                        width: "100%",
                        border: "10px solid #1E73BE",
                        borderRadius: "15px",
                       
                    }}
                    videoStyle={{
                        width: "100% ",
                        objectFit: "cover"
                    
                    }}
                    onResult={(result, error) => {
                        onScan(result)
                       
                    }}
                    scanDelay={scanDelay}
                />

            </div>
        </div>

    )
}

export default EventQRReader;