import React, { useState, useEffect } from "react";
import { Avatar, Divider, notification } from "antd";
// import 'antd/dist/antd.css';
import { useTranslation } from "react-i18next";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import BlogPostCategoryTable from "./BlogPostCategoryTable";
import BlogPostCategoryModal from "./BlogPostCategoryModal";

const debug = require("debug")("app:admin:client:src:BlogPostList");

const involvedModelName = "company";
const selectedKey = "blog_post_category";
const openKeys = "blog"
const tableIDName = "blog_post_id";

const BlogPostCategoryList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t("blog_post_category");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call("get", "/api/blog/post/category");
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t("error"),
        description: t(result.errorCode),
      });
    }
    let { blogPostCategoryArr } = result.data;
    console.log("data", blogPostCategoryArr);
    setDataList(blogPostCategoryArr);
    setLoading(false);
  };

  const handleFormSubmit = async (submitRecord) => {
    try {
      setLoading(true);
      let method = submitRecord.blog_post_category_id === 0 ? "put" : "patch";
      let submit = await Service.call(
        method,
        "/api/blog/post/category",
        submitRecord
      );
      if (submit.status < 1) {
        notification.error({
          message: t("fail"),
          description: t(submit.errorCode),
        });
        return setLoading(false);
      }
      notification.success({
        message: t("success"),
      });
      setModalVisible(false);
      getAllData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true);
    let postData = {
      blog_post_category_id: deleteRecord,
    };
    let submit = await Service.call(
      "patch",
      `/api/blog/post/category/delete`,
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleIsActiveChange = async (blog_post_category_id, value) => {
    setLoading(true);
    let postData = {
      blog_post_category_id,
      is_active: value,
    };
    let resp = await Service.call(
      "patch",
      "/api/blog/post/category/active",
      postData
    );
    if (resp.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(resp.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <NavAndSideFrame title={title} key={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true);
          setSelectedRecord({ blog_post_category_id: 0 });
        }}
      >
        {t("add_item")}
      </Button>
      <Divider />

      <BlogPostCategoryTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        handleDeleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(blog_post_category_id, value) => {
          handleIsActiveChange(blog_post_category_id, value);
        }}
        loading={loading}
      />
      <BlogPostCategoryModal
        selectedRecord={selectedRecord}
        dataObj={dataList}
        modalVisible={modalVisible}
        setModalVisible={(value) => {
          setModalVisible(value);
          getAllData();
        }}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        loading={loading}
      />
    </NavAndSideFrame>
  );
};

export default BlogPostCategoryList;
