import React, {
  Component, useState, useEffect, useRef
} from "react";
import {
  LockOutlined, MailOutlined, KeyOutlined, UserOutlined
} from "@ant-design/icons";
import {
  Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, notification, Modal
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setAuth, setCompanyAdmin } from "../../redux/actions/common";
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from "../../core/Service";
import logo from "../../assets/dice_creative_login_logo.png";


const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [forgetPwdForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector((state) => state.app.config);
  const auth = useSelector((state) => state.app.auth);
  const [getCodeText, setGetCodeText] = useState(t("get_code"));
  const [isResendDisable, setResendDisable] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [forgetPwdModalVisible, setForgetPwdModalVisible] = useState(false);
  const timerRef = useRef(null);

  const openResetPwdModal = () => {
    setForgetPwdModalVisible(true);
  };

  const sendEmail = async (type) => {
    let testEmail = type === "login" ? form.getFieldValue("email") : forgetPwdForm.getFieldValue("email");
    if ((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(testEmail))) {
      notification.info({
        message: t("sent"),
        description: t("sent_success")
      });
      const resp = await Service.call(
        "post",
        "/api/login/admin/otp/send_forget_password_email", {
          email: testEmail
        }
      );
      console.log("🚀 ~ file: Login.js:53 ~ sendEmail ~ resp:", resp)
      setResendDisable(true);
      setIsStartTimer(true);
    } else {
      notification.error({
        message: t("error"),
        description: t("wrong_email_format")
      });
    }
  };

  const onFinish = async (formData) => {
    try {
      let data = await Service.call("post", `/api/login/company_admin`, formData);
      if (data.status === 1) {
        let allPermission = [];
        let resp = await Service.call("get", "/api/config");
        if (resp && resp.status > 0) {
          allPermission = resp.allPermission;
        } else {
          throw new Error("failed to get app config");
        }
        message.success(`${t("login_success")}`);
        dispatch(setCompanyAdmin(data.userData));
        dispatch(setAuth(true));
        // Set Permission By CSS
        let myPermssion = data.permissionList;
        if (!_.includes(myPermssion, "p_any")) {
          const missingPermission = _.difference(allPermission, myPermssion);
          const el = document.getElementsByTagName("body")[0];
          _.each(missingPermission, (perm) => {
            el.classList.add(`${perm}_none`);
          });
        }
        history.push("/company/home");
      } else {
        message.error(`${t("login_fail")}`);
      }
    } catch (error) {
      message.error(`${t("login_fail")}`);
    }
  };

  const onPasswordFormFinish = async (formData) => {
    console.log("🚀 ~ file: Login.js:97 ~ onPasswordFormFinish ~ formData:", formData)
    try {
      const {
        verification_code,
        email,
        password
      } = formData;

      let requestObj = {
        code: verification_code,
        email,
        password
      };
      const resp = await Service.call("post", `/api/login/admin/forget_password`, requestObj);
      if (resp.status === 1) {
        console.log("🚀 ~ file: Login.js:119 ~ onPasswordFormFinish ~ resp:", resp)
        notification.info({
          message: t("reset_success"),
          description: t("please_login")
        });
        setResendDisable(false);
        setGetCodeText(t("get_code"));
        setCounter(60);
        setIsStartTimer(false);
        setForgetPwdModalVisible(false);
      } else {
        message.error(`${t("reset_fail")}`);
      }
    } catch (error) {
      console.error("Error >>", error);
      message.error(`${t("reset_fail")}`);
    }
  };

  const displayLogin = () => {
    return (
      <div className="" style={{display: "flex", justifyContent: "center", marginBottom: "150px"}}>
        <Form
          className="login-form col-12 mt-2"
          style={{width: "100%"}}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="company_key"
            rules={[{ required: true, message: "Please input company key." }]}
          >
            <Input
              prefix={<KeyOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={t("companyKey")}
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t("username")}
            />
          </Form.Item>
          {/* <Form.Item
            name="mobile"
            rules={[{ required: true, message: 'Please input mobile.' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Mobile"
            />
          </Form.Item> */}

          <Form.Item
            name="password"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("password")}
            />
          </Form.Item>

          <Button
            style={{width: "100%"}}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
          {/* <p className="forgot-password text-right">
              Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
          </p> */}

          <Button
            style={{width: "100%", marginTop: 10 }}
            type="link"
            className="login-form-button"
            onClick={() => openResetPwdModal()}
          >
            {t("reset_password")}
          </Button>
        </Form>
      </div>
    );
  };

  const displayForgetPasswordForm = () => {
    return (
      <div className="" style={{display: "flex", justifyContent: "center", marginBottom: "150px"}}>
        <Form
          className="login-form col-12 mt-2"
          style={{width: "100%"}}
          form={forgetPwdForm}
          onFinish={onPasswordFormFinish}
        >
          <Row gutter={[12, 12]}>
            <Col span={18}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: t("input_required") }]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder={t("email")}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                style={{width: "100%"}}
                type="primary"
                className="login-form-button"
                disabled={isResendDisable}
                onClick={() => sendEmail()}
              >
                {getCodeText}
              </Button>
            </Col>
          </Row>
          <Form.Item
            name="verification_code"
            rules={[{ required: true, message: "Please input verification code." }]}
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Code"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("password")}
            />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("password")}
            />
          </Form.Item>

          <Button
            style={{width: "100%"}}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("reset_password")}
          </Button>
          {/* <p className="forgot-password text-right">
              Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
          </p> */}
        </Form>
      </div>
    );
  };

  return (
    <Layout>
      <Layout.Content>
        <div style={{height: "10px", background: "linear-gradient(to right, #000046, #1cb5e0)"}} />
        <Row type="flex" justify="center" align="middle" style={{padding: "50px", minHeight: "100vh", background: "white"}}>
          <Col xs={24} sm={16} md={14} lg={10} xl={8} style={{alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center", marginBottom: "50px"}}>
              <div>
                <img alt="" src={logo} style={{width: "100%", maxWidth: "300px", marginBottom: "30px"}} />
              </div>
              <h2>{t("vms")}</h2>
              <h3>{t("company_admin_login")}</h3>
            </div>
            {
              displayLogin()
            }
          </Col>
        </Row>
        {/* <Row type="flex" justify="center">
          <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
            <a href={`${config.STATIC_SERVER_URL}/admin/login`}>Technine admin login</a>
          </Col>
        </Row> */}
        <Modal
          title={t("reset_password")}
          visible={forgetPwdModalVisible}
          width={800}
          footer={null}
          style={{ maxWidth: "95%" }}
          onCancel={() => {
            setForgetPwdModalVisible(false);
          }}
        >
          {displayForgetPasswordForm()}
        </Modal>
      </Layout.Content>
    </Layout>
  );
};

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);
