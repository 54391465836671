import React, { useState, useEffect} from "react";
import {
  Divider,
} from "antd";
import {
  PlusOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NavAndSideFrame from "../../../components/NavAndSideFrame";
import Button from "../../../t9UIComponents/t9Button";
import PaymentMethodTable from "./PaymentMethodTable";
import PaymentMethodModal from "./PaymentMethodModal";


const title = "Payment Method Management";
const selectedKey = "payment_method_management";
const openKeys = "payment_method_management";

const PaymentMethodList = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const {t} = useTranslation();
  const [dataList, setDataList] = useState([]);
  let hardCodePaymentList = [
    {
      payment_method_id: 1,
      payment_method_type: "Visa",
      ctime: 1669360020
    },
    {
      payment_method_id: 2,
      payment_method_type: "Master",
      ctime: 1669360020
    },
  ];
  useEffect(() => {
    setDataList(hardCodePaymentList);
  }, []);

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Add
      </Button>
      <Divider />
      <PaymentMethodTable
        loading={loading}
        dataList={dataList}
      />
      <PaymentMethodModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        dataList={dataList}
        setDataList={setDataList}
      />
    </NavAndSideFrame>
  );
};

export default PaymentMethodList;
