import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Space, Table, Tag, Tooltip, Row, Col, Select, message
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import Button from "../../../t9UIComponents/t9Button";
import EventTable from "../../../components/Event/EventTable";
import EventForm from "../../../components/Event/EventForm";
import * as Main from "../../../core/Main";

const { Option } = Select;

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const title = "Event List";
const selectedKey = "event_list";
const openKeys = "event_management";

const EventList = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [periodList, setPeriodList] = useState([]);
  const [categories, setCategories] = useState([]);

  const [eventDataList, setEventDataList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});


  useEffect(() => {
    getAllData();
    getEventCategoryList();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let resp = await Service.call("get", "/api/event");
      if (resp.status === 1) {
        setDataList(resp.data);
        setEventDataList(resp.data);
        setPeriodList(resp.period);
      }
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const getEventCategoryList = async () => {
    try {
      let resp = await Service.call("get", "/api/event/category");
      const {data} = resp;
      const list = _.map(data, (item) => { return {value: item.event_category_id, label: item.title}; });
      setCategories(list);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  const generateEventRegistrationReport = async (
  ) => {
    if (selectedEvent.event_id === undefined
      || selectedEvent.event_id === 0
      || selectedEvent.event_id === null) {
      return;
    }

    let queryParams = {
      event_id: selectedEvent.event_id,
    };

    try {
      let resp = await Service.call("get", "/api/event/registration_report", queryParams);
      if (resp.status !== 1) {
        message.error(resp.errorMessage);
        return;
      }
      if (resp.status === 1) {
        let enrollmentList = resp.data.enrollmentList;
        let reportFileName = moment().unix().toString() + "-event-registration-report";

        Main.exportToCSV(
          enrollmentList,
          reportFileName
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Row gutter={[50, 20]}>
        <Col>
          <span>
            <Tooltip title={t("download_report")}>
              <Button
                style={{ marginRight: 8, color: "#000000" }}
                type="primary"
                onClick={() => {
                  generateEventRegistrationReport();
                }}
              >
                Event Registration Report
              </Button>
            </Tooltip>
          </span>
        </Col>
        <Col>
          <span>Event : </span>
          <Select
            allowClear
            value={selectedEvent.event_id}
            style={{ width: 400 }}
            onChange={(value) => {
              setSelectedEvent({
                event_id: value,
              });
            }}
          >
            {_.map(eventDataList, (eachEvent) => {
              let {
                event_id,
                event_name
              } = eachEvent;
              return (
                <Option key={eachEvent.event_id} value={eachEvent.event_id}>
                  {`(${event_id}) ${event_name}`}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Space>
        {/* <Button
          type="primary"
          onClick={() => {
            setSelectedRecord({});
            setModalVisible(true);
          }}
        >
          {t("add_event")}
        </Button> */}
        <Link
          to={{
            pathname: "/company/add_event",
          }}
        >
          <Button type="primary">{t("add_event")}</Button>
        </Link>
      </Space>
      <Divider />
      <EventTable
        loading={loading}
        setLoading={setLoading}
        dataObj={dataList}
        periodList={periodList}
        getAllData={getAllData}
      />

      {/* <Modal
        title={t("add_event")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => { setModalVisible(false); }}
      >
        <EventForm
          dataObj={selectedRecord}
          onSuccess={() => {
            setModalVisible(false);
            getAllData();
          }}
          categories={categories}
          getEventCategoryList={getEventCategoryList}
        />
      </Modal> */}
    </NavAndSideFrame>
  );
};

export default EventList;
