import React, { Component, useEffect } from "react";
import {
  Layout, Menu, Row, Col, Grid
} from "antd";
import {
  Redirect,
  Link
} from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  LogoutOutlined,
  SettingOutlined,
  CarryOutOutlined,
  DollarCircleOutlined,
  ShoppingOutlined,
  ReconciliationOutlined,
  BuildOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { ActionCreators } from "../redux/actions";
import * as Service from "../core/Service";
import LanguageSelector from "./LanguageSelector";
import { ReactComponent as DashboardLogo } from "../assets/ams_dashboard.svg";
import { ReactComponent as SaleLogo } from "../assets/ams_sale.svg";
import { ReactComponent as NotificationLogo } from "../assets/ams_notification.svg";
import { ReactComponent as BookingLogo } from "../assets/ams_booking.svg";
import { ReactComponent as EventLogo } from "../assets/ams_event.svg";
import { ReactComponent as ProductLogo } from "../assets/ams_product.svg";
import { ReactComponent as UserLogo } from "../assets/ams_user.svg";
import { ReactComponent as MembershipLogo } from "../assets/ams_membership.svg";
import { ReactComponent as BlogLogo } from "../assets/ams_blog.svg";
import { ReactComponent as DisplayLogo } from "../assets/ams_display.svg";
import { ReactComponent as ReportLogo } from "../assets/ams_report.svg";
import { ReactComponent as WebsiteLogo } from "../assets/ams_website.svg";
import { ReactComponent as SettingLogo } from "../assets/ams_setting.svg";
import { ReactComponent as AdminSettingLogo } from "../assets/ams_admin_setting.svg";
import { ReactComponent as PaymentMethodLogo } from "../assets/ams_payment_method.svg";


const { useBreakpoint } = Grid;
const { Sider } = Layout;
const { SubMenu } = Menu;
const activeColor = "#1E73BE";
const defaultColor = "#888888";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      logout: false,
    };
  }

  async toggle() {
    let { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  async logout() {
    await Service.logout();
    this.props.setAdmin({});
    this.props.setCompanyAdmin({});
    this.props.setCompanyUser({});
    this.props.setUser({});
    this.props.setAuth(false);
    // this.props.history.push('/login');
    this.setState({ logout: true });
  }

  renderMenuItem() {
    let {
      defaultSelectedKeys,
      t,
      defaultOpenKeys,
    } = this.props;

    let dataList = [
      {
        key: "dashboard",
        title: t("dashboard"),
        path: "/admin/home",
        adminPath: "/admin/home",
        companyPath: "/company/home",
        icon: <DashboardLogo fill={defaultSelectedKeys === "dashboard" ? activeColor : defaultColor} />,
        privilege: "all",
        className: ["p_dashboard_view", "paddingLeftclassName"],
      },
      {
        key: "sale_and_operations",
        title: "Registration Management", // For particular project, rename it to "Registration Management"
        icon: <SaleLogo fill={defaultOpenKeys === "sale_and_operations" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_sale_and_operations"],
        submenu: [
          {
            key: "order_list",
            title: t("order_list"),
            path: "/",
            companyPath: "/company/order/list",
            privilege: "company"
          },
          // {
          //   key: "transaction_record",
          //   title: t("transaction_record"),
          //   path: "/",
          //   companyPath: "/",
          //   privilege: "company"
          // },
          // {
          //   key: "token_record",
          //   title: t("token_record"),
          //   path: "/",
          //   companyPath: "/company/token/log",
          //   privilege: "company",
          // },
          // {
          //   key: "token_package",
          //   title: t("token_package"),
          //   path: "/",
          //   companyPath: "/company/token/package",
          //   privilege: "company",
          // },
          // {
          //   key: "payment_management",
          //   title: t("payment_management"),
          //   path: "/",
          //   companyPath: "/company/company_payment_setting",
          //   privilege: "company",
          //   className: ["p_payment_management"],
          // },
          // {
          //   key: "shipping_method",
          //   title: t("ams_shipping_method"),
          //   path: "/",
          //   companyPath: "/company/shipping_method",
          //   privilege: "company"
          // }
        ]
      },
      // {
      //   key: "booking_management",
      //   title: t("booking_management"),
      //   icon: <BookingLogo fill={defaultOpenKeys === "booking_management" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   className: ["p_booking_item_management"],
      //   submenu: [
      //     {
      //       key: "booking_record",
      //       title: t("booking_record"),
      //       path: "/",
      //       companyPath: "/company/booking_log",
      //       privilege: "company"
      //     },
      //     {
      //       key: "booking_items_list",
      //       title: t("booking_items_list"),
      //       path: "/",
      //       companyPath: "/company/booking/item/list",
      //       privilege: "company"
      //     },
      //     {
      //       key: "booking_config",
      //       title: t("booking_config"),
      //       path: "/",
      //       companyPath: "/company/booking/config",
      //       privilege: "company"
      //     },
      //     {
      //       key: "booking_category",
      //       title: t("category"),
      //       path: "/",
      //       companyPath: "/company/booking/category/list",
      //       privilege: "company"
      //     },
      //   ]
      // },
      {
        key: "event_management",
        title: "Event List Management", // For particular project, rename it to "Event List Management"
        icon: <EventLogo fill={defaultOpenKeys === "event_management" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_event_management"],
        submenu: [
          {
            key: "event_list",
            title: t("event_list"),
            path: "/",
            companyPath: "/company/event",
            privilege: "company"
          },
          // {
          //   key: "check_in",
          //   title: t("check_in"),
          //   path: "/",
          //   companyPath: "/company/event/check_in",
          //   privilege: "company"
          // },
          {
            key: "event_category",
            title: t("category"),
            path: "/",
            companyPath: "/company/event/category",
            privilege: "company"
          },
        ]
      },
      {
        key: "notification",
        title: t("notification"),
        icon: <NotificationLogo fill={defaultOpenKeys === "notification_management" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_notification"],
        submenu: [
          {
            key: "notification_list",
            title: t("notification_list"),
            path: "/",
            companyPath: "/company/notification",
            privilege: "company"
          },
        ]
      },
      // {
      //   key: "product",
      //   title: t("product"),
      //   icon: <ProductLogo fill={defaultOpenKeys === "product" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   className: ["p_display_management"],
      //   submenu: [
      //     {
      //       key: "product_item_list",
      //       title: t("ams_product_item_list"),
      //       path: "/",
      //       companyPath: "/company/product/item/list",
      //       privilege: "company"
      //     },
      //     {
      //       key: "product_category_management",
      //       title: t("category"),
      //       path: "/",
      //       companyPath: "/company/product/category/list",
      //       privilege: "company"
      //     },
      //   ]
      // },
      {
        key: "payment_method_management",
        title: "Payment Method Management", // For particular project
        icon: <PaymentMethodLogo fill={defaultOpenKeys === "payment_method_management" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_payment_method"],
        submenu: [
          {
            key: "payment_method_management",
            title: t("payment_method"),
            path: "/",
            companyPath: "/company/payment_method",
            privilege: "company"
          },
        ]
      },
      {
        key: "user_list",
        title: "Member Management", // For particular project, rename it to "Member Management"
        icon: <UserLogo fill={defaultOpenKeys === "user" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_user"],
        submenu: [
          {
            key: "user_list",
            title: t("user_list"),
            path: "/",
            companyPath: "/company/user/list",
            privilege: "company"
          },
        ]
      },
      // {
      //   key: "membership",
      //   title: t("membership"),
      //   icon: <MembershipLogo fill={defaultOpenKeys === "membership" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   // className: ["p_membership"],
      //   // companyPath: "/company/home",
      //   submenu: [
      //     {
      //       key: "membership_plan",
      //       title: t("membership_plan"),
      //       path: "/",
      //       companyPath: "/company/membership",
      //       privilege: "company"
      //     },
      //     {
      //       key: "membership_user",
      //       title: t("membership_user"),
      //       path: "/",
      //       companyPath: "/company/user/membership",
      //       privilege: "company"
      //     },
      //   ]
      // },
      {
        key: "blog",
        title: "Blog/ News Management", // For particular project, rename it to "Blog/ News Management"
        icon: <BlogLogo fill={defaultOpenKeys === "blog" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_blog"],
        submenu: [{
          key: "blog_post_item",
          title: t("blog_list"),
          path: "/",
          companyPath: "/company/blog_post",
          privilege: "company"
        },
        {
          key: "blog_post_category_key",
          title: t("category"),
          path: "/",
          companyPath: "/company/blog_post/category",
          privilege: "company"
        },
        ]
      },
      // {
      //   key: "display",
      //   title: t("display"),
      //   icon: <DisplayLogo fill={defaultOpenKeys === "display" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   className: ["p_display"],
      //   submenu: [
      //     {
      //       key: "display_list",
      //       title: t("display_list"),
      //       path: "/",
      //       companyPath: "/company/display/list",
      //       privilege: "company"
      //     },
      //   ]
      // },

      // --- Comment: For particular project, comment some codes
      // {
      //   key: "report_key",
      //   title: t("report"),
      //   path: "/",
      //   companyPath: "/company/report",
      //   icon: <ReportLogo fill={defaultOpenKeys === "report_key" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   className: ["p_report_management"],
      //   submenu: [
      //     {
      //       key: "export",
      //       title: t("export"),
      //       path: "/",
      //       companyPath: "/company/report",
      //       privilege: "company"
      //     },
      //   ]
      // },
      // --- Comment: For particular project, comment some codes
      // {
      //   key: "website_management",
      //   title: t("website_management"),
      //   icon: <WebsiteLogo fill={defaultOpenKeys === "website_management" ? activeColor : defaultColor} />,
      //   privilege: "company",
      //   className: ["p_website_management"],
      //   submenu: [
      //     {
      //       key: "banner",
      //       title: t("banner"),
      //       path: "/",
      //       companyPath: "/company/banner",
      //       privilege: "company"
      //     },
      //     {
      //       key: "company_site_meta",
      //       title: t("site_meta"),
      //       path: "/",
      //       companyPath: "/company/site_meta",
      //       privilege: "company",
      //     },

      //     {
      //       key: "shop_info",
      //       title: t("shop_info"),
      //       path: "/",
      //       companyPath: "/company/shop_info",
      //       privilege: "company",
      //     }
      //   ]
      // },
      {
        key: "settings",
        title: t("settings"),
        icon: <SettingLogo fill={defaultOpenKeys === "settings" ? activeColor : defaultColor} />,
        privilege: "company",
        className: ["p_setting_management"],
        submenu: [
          {
            key: "company_setting",
            title: t("company_setting"),
            path: "/",
            companyPath: "/company/company_setting",
            privilege: "company",
          },
          {
            key: "email_template",
            title: t("email_template"),
            path: "/",
            companyPath: "/company/email/list",
            privilege: "company",
          },
          // {
          //   key: "company_config",
          //   title: t("company_config"),
          //   path: "/",
          //   companyPath: "/company/company_config",
          //   privilege: "company",
          // },
        ]
      },
      {
        key: "admin_setting_company",
        title: t("admin_setting"),
        icon: <AdminSettingLogo fill={defaultOpenKeys === "admin_setting_company" ? activeColor : defaultColor} />,
        privilege: "company",
        submenu: [
          {
            key: "company_admin_list",
            title: t("company_admin_list"),
            path: "/",
            companyPath: "/company/admin/list",
            privilege: "company",
          },
          {
            key: "admin_permission_setting",
            title: t("admin_permission"),
            path: "/",
            companyPath: "/company/admin_permission_setting",
            privilege: "company",
          },
          {
            key: "company_account_setting",
            title: t("account_setting"),
            path: "/",
            companyPath: "/company/account_setting",
            privilege: "company",
          },
        ]
      },
      {
        key: "admin_setting",
        title: t("admin_setting"),
        icon: <AdminSettingLogo fill={defaultOpenKeys === "admin_setting" ? activeColor : defaultColor} />,
        privilege: "admin",
        submenu: [
          {
            key: "company_admin_list",
            title: t("admin_setting"),
            path: "/",
            adminPath: "/admin/company/list",
            privilege: "admin",
          },
          {
            key: "admin_company_admin_list",
            title: t("company_user_list"),
            path: "/",
            adminPath: "/admin/company/admin/list",
            privilege: "admin",
          },
        ]
      },
      // {
      //   key: 'available_booking',
      //   title: t('available_booking'),
      //   path: '/',
      //   userPath: '/user/available_booking',
      //   icon: <UserOutlined />,
      //   privilege: 'user'
      // },
      // {
      //   key: 'user_setting',
      //   title: t('user_setting'),
      //   path: '/',
      //   userPath: '/user/user_account_form',
      //   icon: <UserOutlined />,
      //   privilege: 'user'
      // },
    ];
    let admin = false;
    admin = !_.isEmpty(this.props.app.admin);
    if (!_.isEmpty(this.props.app.company_admin)) {
      admin = false;
    }
    if (!_.isEmpty(this.props.app.user)) {
      admin = false;
    }

    let isCompanyAdmin = false;
    isCompanyAdmin = !_.isEmpty(this.props.app.company_admin);


    return dataList.map((pathData, i) => {
      //  privilege remark: all / admin / company / user
      if (!admin && pathData.privilege === "admin") {
        return;
      }

      if (admin && pathData.privilege === "company") {
        return;
      }

      if (admin && pathData.privilege === "user") {
        return;
      }

      if (isCompanyAdmin && pathData.privilege === "admin") {
        return;
      }

      if (isCompanyAdmin && pathData.privilege === "user") {
        return;
      }

      if (!isCompanyAdmin && pathData.privilege === "company") {
        return;
      }
      let redirectPath = pathData.path;
      if (admin) {
        redirectPath = pathData.adminPath;
      }
      if (isCompanyAdmin) {
        redirectPath = pathData.companyPath;
      }
      if (pathData.submenu) {
        return (
          <SubMenu
            key={pathData.key}
            icon={(
              <div style={pathData.key === defaultOpenKeys ? {
                display: "flex",
                alignItems: "center",
                margin: "0 10px 0 0",
                borderLeft: `6px solid ${activeColor}`,
                paddingLeft: "44px",

              } : {
                display: "flex",
                alignItems: "center",
                margin: "0 10px 0 0",
                paddingLeft: "50px"
              }}
              >
                {pathData.icon}
              </div>
            )}
            title={(
              <div style={{ display: "flex", alignItems: "center" }}>
                {pathData.title}
              </div>
            )}
            className={pathData.className ? pathData.className.join(" ") : ""}
          >
            {pathData.submenu.map((subPathData, i) => {
              if (admin) {
                redirectPath = subPathData.adminPath;
              }
              if (isCompanyAdmin) {
                redirectPath = subPathData.companyPath;
              }
              return (
                <Menu.Item
                  key={subPathData.key}
                  className={subPathData.className ? subPathData.className.join(" ") : ""}

                >
                  <Link to={redirectPath}>
                    {subPathData.title}
                  </Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={pathData.key}
          icon={(
            <div
              style={pathData.key === defaultSelectedKeys ? {
                display: "flex",
                alignItems: "center",
                margin: "0 10px 0 0",
                borderLeft: `6px solid ${activeColor}`,
                paddingLeft: "44px",
              } : {
                display: "flex",
                alignItems: "center",
                margin: "0 10px 0 0",
                paddingLeft: "50px",
              }}
            >
              <div style={{ display: "flex", paddingRight: "10px" }}>
                {pathData.icon}
              </div>
              <Link to={redirectPath}>
                <div style={pathData.key === defaultSelectedKeys ? {
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                  fontSize: "14px",
                } : {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.65)",
                  backgroundColor: "transparent"
                }}
                >
                  <div style={{ display: "flex", paddingRight: "5px" }}>
                    {pathData.title}
                  </div>
                </div>
              </Link>
            </div>
          )}
          className={pathData.className ? pathData.className.join(" ") : ""}
        />
      );
    });
  }


  render() {
    // console.log('this.props.app>>>', this.props.app)
    let {
      defaultSelectedKeys,
      t,
      app = {},
      defaultOpenKeys
    } = this.props;

    const {
      config = {},
    } = app;

    const {
      VERSION_NO = "",
    } = config;

    return (
      <Sider
        // collapsed={this.state.collapsed}
        collapsed={this.props.app.sidebarCollapse}
        breakpoint="sm"
        collapsedWidth="0"
        width={330}
        style={{
          height: "100%",
          opacity: 1,
          position: "relative",
          minHeight: "95vh",
          backgroundColor: "#fff"
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={[defaultOpenKeys]}
          style={{ paddingBottom: "50px", paddingTop: "20px", backgroundColor: "#fff"}}
        >
          {this.renderMenuItem()}
        </Menu>
        <Menu mode="inline" style={{backgroundColor: "#fff"}}>
          {/* <Divider /> */}
          <Menu.Item key="99" style={{paddingLeft: 40}} onClick={() => { this.logout(); }}>
            <LogoutOutlined />
            <span>{t("logout")}</span>
          </Menu.Item>
          <Menu.Item key="100">
            <Row>
              <Col xs={24} sm={0}>
                <LanguageSelector />
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item key="101" disabled>
            {`v${VERSION_NO}`}
          </Menu.Item>
        </Menu>

        {
          this.state.logout === true
            ? <Redirect to="/admin/login" />
            : null
        }
        <ScreenDetector />
      </Sider>
    );
  }
}

function ScreenDetector(props) {
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  useEffect(() => {
    const isMobile = screens.xs;
    if (isMobile) {
      dispatch(ActionCreators.toggleSider(true));
    } else {
      dispatch(ActionCreators.toggleSider(false));
    }
  }, [screens]);

  return null;
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sidebar));
