import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Divider, Tabs, Spin, Input, Row, Col } from "antd";
import { useLocation } from "react-router-dom";
// import 'antd/dist/antd.css';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import queryString from "query-string";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import CompanyUserAcListTable from "./CompanyUserAcListTable";
import SearchBar from "../../../components/SearchBar";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "user_list";
const openKeys = "user"
const { TabPane } = Tabs;

const FILTERABLE_FIELDS = [
  "email",
  "username",
  "nickname",
  "mobile",
  "company_user_id",
];

const CompanyUserAcList = (props) => {
  const [loading, setLoading] = useState(true);
  const dataList = useRef({
    userList: [],
    companyConfigRc: {},
  });
  const [filterDataList, setFilterDataList] = useState({
    userList: [],
    companyConfigRc: {},
  });
  const { t } = useTranslation();

  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key;

  const title = t("user_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      let url = "/api/company/user/with/token/by/company";
      let resp = await Service.call("get", url);
      let { companyUserList, tokenList, companyConfigObj } = resp.data;
      let keyById_tokenList = _.keyBy(tokenList, "company_user_id");
      companyUserList = _.map(companyUserList, (eachUser) => {
        let userTokenRc = keyById_tokenList[eachUser.company_user_id];
        return {
          ...eachUser,
          token_own: userTokenRc ? userTokenRc.token_own : 0,
          token_total: userTokenRc ? userTokenRc.token_total : 0,
          token_expiry_time: userTokenRc ? userTokenRc.token_expiry_time : 0,
          token_expired: userTokenRc ? userTokenRc.token_expired : 0,
        };
      });
      let data = {
        userList: companyUserList,
        companyConfigRc: companyConfigObj,
      };
      dataList.current = data;
      setFilterDataList(data);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Link
        to={{
          pathname: "/company/user/form",
          state: {
            companyConfigRc: dataList.current.companyConfigRc,
          },
        }}
      >
        <Button type="primary">{t("add_user")}</Button>
      </Link>
      <Divider />
      <Row align="middle">
        <Col>
          <span>{`${t("search_keyword")}：`}</span>
        </Col>
        <Col>
          <SearchBar
            originalDataList={dataList.current}
            filterDataListKey="userList"
            filterDataList={dataList.current.userList}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
          />
        </Col>
      </Row>
      <Divider />
      <Tabs defaultActiveKey={defaultActiveKey}>
        <TabPane tab={t("confirmed")} key="1">
          <Spin indicator={<LoadingOutlined />} spinning={loading}>
            <CompanyUserAcListTable
              dataList={filterDataList}
              userFilter="active"
              loading={loading}
              defaultActiveKey={1}
              getAllData={getAllData}
            />
          </Spin>
        </TabPane>
        <TabPane tab={t("disabled")} key="3">
          <CompanyUserAcListTable
            dataList={filterDataList}
            userFilter="inactive"
            loading={loading}
            defaultActiveKey={3}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  );
};

export default CompanyUserAcList;
