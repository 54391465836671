import React, { Component, useState, useEffect } from "react";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Space, Table, Tag, Tooltip,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import Button from "../../../t9UIComponents/t9Button";
import NotificationTable from "./NotificationTable";
import NotificationModal from "./NotificationModal";


const title = "Notification List";
const selectedKey = "notification"; // dont know what is this
const openKeys = "notification_management"; // dont know what is this

const NotificationList = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const {t} = useTranslation();

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setSelectedRecord({}); // remember to set empty
          setModalVisible(true);
        }}
      >
        {t("add_notification")}
      </Button>
      <Divider />
      <NotificationTable
        loading={loading}
        setLoading={setLoading}
        setModalVisible={setModalVisible}
        setSelectedRecord={setSelectedRecord}
        forceRefresh={forceRefresh}
      />
      <NotificationModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
    </NavAndSideFrame>
  );
};

export default NotificationList;
