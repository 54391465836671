import React, { Component, useEffect } from "react";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { setAuth, setAdmin, setIsAdmin } from "../../redux/actions/common";
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from "../../core/Service";
import logo from "../../assets/dice_creative_login_logo.png";



const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector((state) => state.app.config);
  // const app = useSelector(state => state.app);

  // useEffect(() => {
  //   console.log('app>>', app)
  // }, []);

  const onFinish = async (formData) => {
    let {username, password} = formData;
    let data = await Service.call("post", `/api/login/admin`, {
      username, password
    });
    // console.log('data>>>', data)
    // if (data.errorMessage) return message.error(data.errorMessage);

    if (data.status && data.status <= 0) {
      message.error(`${t("login_fail")}`);
      dispatch(setAuth(false));
      return;
    }
    let adminData = await Service.call("get", `/api/admin`);


    console.log("adminData>>", adminData);
    // if (adminData.errorMessage) return dispatch(setAuth(false));
    // if (_.isEmpty(adminData.userData)) return dispatch(setAuth(false));

    dispatch(setAdmin(adminData.userData[0]));
    dispatch(setAuth(true));
    dispatch(setIsAdmin(true));
    message.success(`${t("login_success")}`);
    // console.log('app>>', app)
    history.push("/admin/home");
  };

  const displayLogin = () => {
    return (
      <div className="" style={{display: "flex", justifyContent: "center"}}>
        <Form
          className="login-form col-12 mt-2"
          style={{width: "100%"}}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder={t("username")}
              maxLength={40}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t("input_required") }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("password")}
            />
          </Form.Item>
          <Button
            style={{width: "100%"}}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
          {/* <p className="forgot-password text-right">
              Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
          </p> */}
        </Form>
      </div>
    );
  };

  return (
    <Layout>
      <Layout.Content>
        <div style={{height: "10px", background: "linear-gradient(to right, #000046, #1cb5e0)"}} />
        <Row type="flex" justify="center" align="middle" style={{padding: "50px", minHeight: "100vh", background: "white"}}>
          <Col xs={24} sm={16} md={14} lg={10} xl={8} style={{alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center", marginBottom: "50px"}}>
              <div>
                <img alt="" src={logo} style={{width: "100%", maxWidth: "300px", marginBottom: "30px"}} />
              </div>
              <h2>{t("vms")}</h2>
              <h3>{t("admin_login")}</h3>
            </div>
            {
              displayLogin()
            }
          </Col>
        </Row>
        {/* <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <a href={`${config.STATIC_SERVER_URL}/company/login`}>Company admin login</a>
            </Col>
          </Row> */}
      </Layout.Content>
    </Layout>
  );
};

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);
