import React, { Component, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import debugLib from 'debug';
import { ActionCreators } from '../../redux/actions';
import * as Service from '../../core/Service';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

const debug = debugLib('app:AppContainer');


const involvedModelName = "company";
const selectedKey = "dashboard";
const tableIDName = "company_user_id";
const title = "儀表板"

const Home = (props) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app)

  useEffect(() => {
    debug('app>>', app)
  })

  return (
    <NavAndSideFrame title={t("dashboard")} selectedKey={selectedKey}>
      <Row gutter={24}>
        <></>
      </Row>
    </NavAndSideFrame>
  )
}

export default Home
