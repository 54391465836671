import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '../../../t9UIComponents/t9Button';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import BannerPostListTable from './BannerPostListTable';
import BannerPostModal from './BannerPostModal';


const debug = require('debug')('app:admin:client:src:BlogPostList');
const selectedKey = "banner"
const openKeys = "website_management"

const BannerPostList = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation()
  const [logList, setLogList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const title = t('banner');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call('get', '/api/banner');
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(result.errorCode),
      });
    }
    let { data } = result;
    setDataList(data);
    setLoading(false);
  }

  const handleFormSubmit = (submitRecord) => {

    if (selectedRecord.banner_id === 0) {
      handlePutRecord(submitRecord);
    }
    if (selectedRecord.banner_id > 0) {
      handlePatchRecord(submitRecord);
    }
  }

  const handlePutRecord = async (submitRecord) => {
    submitRecord.banner = submitRecord.fileinfo.filepath;
    setLoading(true);

    let submit = await Service.call('put', '/api/banner', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      banner_id: selectedRecord.banner_id,
      ...submitRecord
    }
    patchData.banner = submitRecord.fileinfo.filepath;
    let submit = await Service.call('patch', '/api/banner', patchData);
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  // const handleDeleteRecord = async (deleteRecord) => {
  //   setLoading(true)
  //   let postData = {
  //     banner_id: deleteRecord,
  //   }
  //   let submit = await Service.call('put', '/api/web/delete', postData)
  //   if (submit.status < 1) {
  //     notification.error({
  //       message: t('fail'),
  //     });
  //     setLoading(false)
  //   } else {
  //     notification.success({
  //       message: t('success'),
  //     });
  //     getAllData();
  //     setLoading(false)
  //     setModalVisible(false)
  //   }
  // }

  // const handleIsActiveChange = async (banner_id, value) => {
  //   setLoading(true)
  //   let postData = {
  //     banner_id,
  //     is_active: value
  //   }
  //   let submit = await Service.call('patch', '/api/blog/post/is_active', postData)
  //   if (submit.status < 1) {
  //     notification.error({
  //       message: t('fail'),
  //     });
  //     setLoading(false)
  //   } else {
  //     notification.success({
  //       message: t('success'),
  //     });
  //     getAllData();
  //     setLoading(false)
  //     setModalVisible(false)
  //   }
  // }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({ banner_id: 0 })
        }}
      >
        {t('add_item')}
      </Button>
      <Divider />
      <Divider />

      <BannerPostListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        loading={loading}
      />
      <BannerPostModal
        selectedRecord={selectedRecord}
        dataObj={dataList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value);
            getAllData();
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </NavAndSideFrame>
  )
}

export default BannerPostList;
