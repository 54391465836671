import React, { useState, useEffect } from "react";
import {
  Tabs, Spin,
} from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import NavAndSideFrame from "../../../components/NavAndSideFrame";
import UserAcInfoForm from "../../../components/UserAcInfoForm";
import UserAcStatusForm from "../../../components/UserAcStatusForm";
import UserConfigForm from "../../../components/UserConfigForm";
import UserAcResetPasswordForm from "../../../components/UserAcResetPasswordForm";

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const selectedKey = "user_list"
const openKeys = "user"

const CompanyUserAcContainer = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const app = useSelector((state) => state.app);
  const { isAdmin } = app;

  const { userInfo, companyConfigRc } = location.state || {};
  let query = useQuery();
  let defaultKey = query.get("key") || 1;
  const [userObj, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      fetchUser();
    }
  }, []);


  const fetchUser = async () => {
    setLoading(true);
    let [mobilePrefix, mobile] = _.split(userInfo.mobile, "-");
    userInfo.mobilePrefix = mobilePrefix;
    userInfo.mobile = mobile;
    setUser(userInfo);
    setLoading(false);
  };

  return (
    <NavAndSideFrame
      {...props}
      title={userObj.company_user_id > 0 ? `${t("edit")} ${t("user")}` : `${t("add")} ${t("user")}`}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Spin size="large" spinning={loading}>
        <Tabs type="card" defaultActiveKey={defaultKey} onChange={(key) => null}>
          <TabPane tab={t("user_info")} key={1}>
            <UserAcInfoForm
              initialValues={userObj}
              companyConfigRc={companyConfigRc}
              isAdmin={isAdmin}
              setUser={setUser}
            />
          </TabPane>
          {/* {userObj.company_user_id > 0 && (
            <TabPane tab={t("user_config")} key={2}>
              <UserConfigForm
                initialValues={userObj}
                isAdmin
              />
            </TabPane>
          )} */}
          {userObj.company_user_id > 0 && (
            <TabPane tab={t("user_status")} key={3}>
              <UserAcStatusForm
                initialValues={userObj}
                isAdmin
              />
            </TabPane>
          )}

          {userObj.company_user_id > 0 && (
            <TabPane tab={t("edit_password")} key="userPassword">
              <UserAcResetPasswordForm
                initialValues={userObj}
              />
            </TabPane>
          )}
        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyUserAcContainer;
