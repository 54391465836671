import { delay } from 'lodash'
import React, { Component } from 'react'
import QrReader from 'react-qr-scanner'
import _ from 'lodash'
import {message} from "antd";



const EventQRScanner = (props) => {
  const { onScan } = props
  const handleScan = (data) => {
    console.log(`handleScan data >> `, data)
    try {
      if (!(_.startsWith(data, 'v') && _.endsWith(data, '|') && _.split(data, '.').length > 1)) {
        message.error('QR Code not valid');
        return;
      }

      onScan(data);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="container" style={{width: "100%"}}>
      <div className="QRtitle"
      style={{margin: "20px 0"}}
      >
        Scan to Check-in
      </div>
      <div style={{
                width: "100%",
                // backgroundImage: `url(${QRcodeboarder3})`,
                marginBottom: "50px",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                
            }}>
      <QrReader
        style={{
          border: "10px solid #1E73BE",
          borderRadius: "15px",
          // objectFit: "fill",
          width: "100%",
          transform: "scaleX(-1)"
        }}
        onScan={(obj = {}) => {
          console.log("obj >>", obj)
          if (_.isEmpty(obj)) {
            return;
          }
          const {
            text = '',
          } = obj;

          if (!text) {
            return;
          }
          // console.log(`react-qr-scanner onScan text >> `, text);
          handleScan(text)
        }}
       
      />
</div>
    </div>
  )
}


export default EventQRScanner;