import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  notification,
  Row,
  Col,
  Card,
} from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const { Option } = Select;

const UserAcStatusForm = (props) => {
  let location = useLocation();
  const { t } = useTranslation();
  const { initialValues, isAdmin } = props;
  const id = initialValues.company_user_id;
  const { key } = queryString.parse(location.search);
  const history = useHistory();

  const [UserStatusForm] = Form.useForm();

  useEffect(() => {
    UserStatusForm.resetFields();
  }, [initialValues]);

  const onFinish = async (formData) => {
    formData.company_user_id = id;

    if (id > 0) {
      let response = await Service.call("patch", `/api/company/user/status`, formData);
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t(`error`),
          description: t(response.errorCode),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });

      if (id === 0) {
        history.push("/company/user/list");
      } else {
        history.push(`/company/user/list?key=${key}`);
      }
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col md={24} lg={16}>
        <Card>
          <Form
            form={UserStatusForm}
            name="UserStatusForm"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
          >

            <Form.Item
              label={t("active_status")}
              name="is_active"
              rules={[
                {
                  required: true,
                  message: t('select_required'),
                },
              ]}
            >
              <Select style={{ width: "100%" }} placeholder={t("selectLabel")} disabled={!isAdmin}>
                <Option value={1}>{t("active")}</Option>
                <Option value={0}>{t("inactive")}</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!isAdmin}>
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserAcStatusForm;
