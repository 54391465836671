import React, { useState, useEffect } from "react";
import { Divider, notification, Modal } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Link } from "react-router-dom";
import Button from "../../../t9UIComponents/t9Button";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import ItemInfoListTable from "./ItemInfoListTable";

const debug = require("debug")("app:admin:client:src:AdvertisementList");

const selectedKey = "booking_items_list";
const openKeys = "booking_management";

const ItemInfoList = (props) => {
  const [dataList, setDataList] = useState({
    bookingItemArr: [],
  });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const title = t("item_list");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      setLoading(true);
      let url = "/api/booking_item/list";
      let resp = await Service.call("get", url);

      if (resp.status !== 1 || !resp) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      setDataList({
        bookingItemArr: resp.data,
      });
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRecord = async (booking_item_id) => {
    setLoading(true);
    let postData = {
      booking_item_id,
    };
    let submit = await Service.call(
      "post",
      "/api/booking_item/delete",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
        description: t(submit.errorCode),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  const handleIsActiveChange = async (booking_item_id, value) => {
    setLoading(true);
    let postData = {
      booking_item_id,
      is_active: value,
    };
    let submit = await Service.call(
      "patch",
      "/api/booking_item/is_active",
      postData
    );
    if (submit.status < 1) {
      notification.error({
        message: t("fail"),
      });
      setLoading(false);
    } else {
      notification.success({
        message: t("success"),
      });
      getAllData();
      setLoading(false);
    }
  };

  return (
    <NavAndSideFrame
      title={title}
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <Link
        to={{
          pathname: "/company/booking/item/form",
          state: {
            selectedRecord: { booking_item_id: 0 }
          },
        }}
      >
        <Button type="primary">{t("add_item")}</Button>
      </Link>
      <Divider />
      <ItemInfoListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        deleteRecord={(record) => handleDeleteRecord(record)}
        isActiveChangeCallBack={(booking_item_id, value) => {
          handleIsActiveChange(booking_item_id, value);
        }}
        loading={loading}
      />
    </NavAndSideFrame>
  );
};

export default ItemInfoList;
