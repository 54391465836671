import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import RegistrationTable from "../../components/RegistrationTable";
import * as Service from "../../core/Service"

const Registration = () => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const title = t("registration");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async() => {
    const response = await Service.call("get", "/api/registration");
    if (response?.status === 0 ) return 
    const {registrationList} = response
    setDataList(registrationList);
  };

  return (
    <NavAndSideFrame title={title}>
      <RegistrationTable
        loading={loading}
        setLoading={setLoading}
        dataSource={dataList}
        getAllData={getAllData}
      />
    </NavAndSideFrame>
  );
};

export default Registration;
