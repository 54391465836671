import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyAdminRoleForm from '../../components/CompanyAdminRoleForm';
import CompanyAdminRoleTable from '../../components/CompanyAdminRoleTable';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = 3;
const tableIDName = "company_admin_role_id";

const CompanyAdminRoleList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const { t } = useTranslation();
  const selectedKey = "admin_permission_setting"
  const openKeys = "admin_setting_company"
  const title = t("admin_permission_setting");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    let orderedDataList = [];
    try {
      let url = `/api/${involvedModelName}/admin_role`;
      let data = await Service.call('get', url);
      orderedDataList = _.orderBy(data, ['company_admin_role_id']);
      orderedDataList = _.map(orderedDataList, (role) => {
        const permissionList = _.orderBy(role.rolePermissionList, ['permission']);
        return {
          ...role,
          permissionList
        }
      })
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      console.log('orderedDataList', orderedDataList);
      setDataList(orderedDataList);
      setLoading(false);
    }
  }

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKeys={openKeys}>
      <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_admin_role_id: 0 });
          setModalVisible(true);
        }}
      >
        {t("add_admin_role")}
      </Button>
      <Divider />
      {/* <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}

      /> */}
      <CompanyAdminRoleTable
        dataObj={dataList}
        companyChangeData={getAllData}
        setModalVisible={setModalVisible}
        setSelectedRecord={setSelectedRecord}
      />
      <Modal
        title="Edit"
        visible={modalVisible}
        footer={null}
        width={1280}
        style={{ maxWidth: "95%" }}
        onCancel={() => { setModalVisible(false) }}
      >
        <CompanyAdminRoleForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              getAllData();
              setModalVisible(value)
            }
          }
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default CompanyAdminRoleList;
