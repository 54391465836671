import React, { useEffect, useState } from "react";
import "@ant-design/compatible/assets/index.css";
import { Button, Row, Col, Card, notification, Table, Tag } from "antd";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import debugLib from "debug";
import { useTranslation } from "react-i18next";
import _, { each } from "lodash";
import dayjs from "dayjs";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import * as Service from "../../core/Service";

const debug = debugLib("app:AppContainer");

const tableIDName = "booking_item_id";
const INTERVAL_TIME = 60000;
const selectedKey = "dashboard";

let interval = _.noop();

const BOOKING_STATUS_NAME = {
  AVAILABLE: "available",
  HOLD: "hold",
  CONFIRM_BOOKED: "confirm_booked",
};

const BOOKING_STATUS = {
  [BOOKING_STATUS_NAME.AVAILABLE]: 0,
  [BOOKING_STATUS_NAME.HOLD]: 2,
  [BOOKING_STATUS_NAME.CONFIRM_BOOKED]: 10,
};

const Home = (props) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState({
    orderList: [],
    bookingItemList: [],
    keyByCurrentBookingObj: {},
    keyByCompanyUserObj: {},
  });

  const ORDER_STATISTICS_LIST = [
    {
      title: "confirmed_order_month",
      // value: dataList.orderList.length,
      value: dataList.monthCfmOrderCount,
      backgroundColor: "#FBF9F9",
      textColor: "#000000",
    },
    {
      title: "order_amount_month",
      // value: `$ ${_.sumBy(dataList.orderList, "final_price")}`,
      value: `$ ${dataList.monthOrderAmt}`,
      backgroundColor: "#25a0ce",
      textColor: "#FFFFFF",
    },
    {
      title: "confirmed_order_today",
      // value: _.filter(dataList.orderList, (eachOrder) => {
      //   return (
      //     eachOrder.ctime >= dayjs().startOf("d").unix() &&
      //     eachOrder.ctime <= dayjs().endOf("d").unix()
      //   );
      // }).length,
      value: dataList.dayCfmOrdersCount,
      backgroundColor: "#FBF9F9",
      textColor: "#000000",
    },
    {
      title: "order_amount_today",
      // value: `$ ${_.sumBy(
      //   _.filter(dataList.orderList, (eachOrder) => {
      //     return (
      //       eachOrder.ctime >= dayjs().startOf("d").unix() &&
      //       eachOrder.ctime <= dayjs().endOf("d").unix()
      //     );
      //   }),
      //   "final_price"
      // )}`,
      value: `$ ${dataList.dayOrderAmt}`,

      backgroundColor: "#fba31f",
      textColor: "#FFFFFF",
    },
  ];

  useEffect(() => {
    getDashboardData();
    interval = setInterval(() => {
      getDashboardData();
    }, INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getDashboardData = async () => {
    try {
      setLoading(true);

      const resp = await Service.call(
        // "get",
        // `/api/dashboard/overview?current_time=${dayjs().unix()}&monthly_start=${dayjs()
        //   .startOf("M")
        //   .unix()}&monthly_end=${dayjs().endOf("M").unix()}`

        "get",
        `/api/dashboard/overview?current_time=${dayjs().unix()}&monthly_start=${dayjs().startOf("month").unix()}&daily_start=${dayjs().startOf("day").unix()}`
      );
      if (resp.status !== 1) {
        return notification.error({
          message: t("error"),
          description: t(resp.errorCode),
        });
      }
      // const {
      //   bookingItemArr = [],
      //   currentBookingArr = [],
      //   monthlyOrderArr = [],
      //   companyUserArr = [],
      // } = resp.data;
      // const keyByCurrentBookingArr = _.keyBy(
      //   currentBookingArr,
      //   "booking_item_id"
      // );
      // const keyByCompanyUserArr = _.keyBy(companyUserArr, "company_user_id");
      // let bookingItemList = _.map(bookingItemArr, (eachBookingItem) => {
      //   const { booking_item_id } = eachBookingItem;
      //   let bookingRecord = keyByCurrentBookingArr[booking_item_id] || {};
      //   let { company_user_id, start_time, end_time } = bookingRecord;
      //   return {
      //     ...eachBookingItem,
      //     company_user_id: company_user_id || 0,
      //     booking_status: !_.isEmpty(bookingRecord) ? bookingRecord.status : 0,
      //     start_time: start_time || 0,
      //     end_time: end_time || 0,
      //   };
      // });
      // setDataList({
      //   orderList: monthlyOrderArr,
      //   bookingItemList,
      //   keyByCurrentBookingObj: keyByCurrentBookingArr,
      //   keyByCompanyUserObj: keyByCompanyUserArr,
      // });
      setDataList(resp.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    return [
      {
        title: t("item_name"),
        dataIndex: `item_name`,
      },
      {
        title: t("status"),
        dataIndex: "booking_status",
        render: (value) => renderBookingStatus(value),
      },
      {
        title: t("company_user"),
        dataIndex: `company_user_id`,
        render: (value) => {
          return dataList.keyByCompanyUserObj[value]
            ? `(${value}) ${dataList.keyByCompanyUserObj[value].nickname}`
            : "-";
        },
      },
      {
        title: t("start_end"),
        dataIndex: `start_time`,
        render: (value, record) => {
          return value !== 0
            ? `${dayjs.unix(value).format("HH:mm")} - ${dayjs
                .unix(record.end_time)
                .format("HH:mm")}`
            : "-";
        },
      },
    ];
  };

  const renderBookingStatus = (value) => {
    let color = "";
    let str = "";
    switch (value) {
      case BOOKING_STATUS[BOOKING_STATUS_NAME.AVAILABLE]:
        color = "green";
        str = "available";
        break;
      case BOOKING_STATUS[BOOKING_STATUS_NAME.HOLD]:
        color = "orange";
        str = "hold";
        break;
      case BOOKING_STATUS[BOOKING_STATUS_NAME.CONFIRM_BOOKED]:
        color = "blue";
        str = "confirm_booked";
        break;
      default:
        color = "red";
        str = "error";
        break;
    }
    return <Tag color={color}>{t(str)}</Tag>;
  };

  return (
    <NavAndSideFrame title={t("dashboard")} selectedKey={selectedKey}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <h3>{t("order_statistics")}</h3>
        </Col>
        {!loading &&
          _.map(ORDER_STATISTICS_LIST, (eachStat) => {
            return (
              <Col xs={12} sm={12} md={8} lg={5}>
                <div
                  style={{
                    backgroundColor: eachStat.backgroundColor,
                    width: "100%",
                    padding: "5% 10%",
                    borderRadius: "8px",
                    height: "100px",
                    color: eachStat.textColor,
                  }}
                >
                  <span style={{ fontSize: "16px" }}>{t(eachStat.title)}</span>
                  <br />
                  <span style={{ fontSize: "20px" }}>{eachStat.value}</span>
                </div>
              </Col>
            );
          })}
      </Row>
      {/* <Row gutter={[24, 24]} style={{ marginTop: "10px" }}>
        <Col span={24}>
          <h3>{t("current_booking")}</h3>
        </Col>
        <Col span={24}>
          <Table
            rowKey={tableIDName}
            scroll={{ x: "max-content" }}
            dataSource={dataList.bookingItemList}
            columns={setTableHeader()}
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            loading={loading}
          />
        </Col>
      </Row> */}
    </NavAndSideFrame>
  );
};

export default Home;
