import React, { useEffect, useState } from "react";
import { Col, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import SearchBar from "../SearchBar";
import { SearchOutlined } from "@ant-design/icons";
import _ from 'lodash'

const data = [
  {
    event_id: 1,
    email: "xxx@technine.io",
    mobile: "852 12345678",
    lname: "Sit",
    fname: "Maggie",
    event_name: "New Product Event",
    event_date_list: [{date: "2022-08-15 - 2022-08-18", time: "13:00 - 14:00"}, {date: "2022-08-17 - 2022-08-18", time: "14:00 - 17:00"}],
  },
  {
    event_id: 6,
    email: "xxx@123.io",
    mobile: "852 74283784",
    lname: "Wong",
    fname: "Peter",
    event_name: "New Product Event",
    event_date_list: [{date: "2022-08-15 - 2022-08-18", time: "13:00 - 14:00"}],
  },
];

const EventAttendanceRecord = () => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);

  useEffect(() => {
    setDataList(data);
    setFilterDataList(data);
  }, [data]);

  const FILTERABLE_FIELDS = ["email", "mobile", "lname", "fname", "event_name"];

  return (
    <div className="event-tabs-page">
      <Row gutter={16} align="middle">
        <Col>{t("search_keywords")}</Col>
        <Col>
          <SearchBar
            originalDataList={dataList}
            filterDataList={dataList}
            setDataList={setFilterDataList}
            filterField={FILTERABLE_FIELDS}
            placeholder="search_keywords"
            suffix={<SearchOutlined style={{color: "#888888"}} />}
          />
        </Col>
      </Row>
      <AttendanceRecordTable data={filterDataList} />
    </div>
  );
};

const AttendanceRecordTable = ({ data }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const tableIDName = "event_id";
  const handleCheckbox = (id) => {
    console.log({ id });
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "event_id",
      render: (value) => <div>{_.toString(value).padStart(4, "0")}</div>,
      sorter: (a, b) => a.event_id - b.event_id,
    },
    {
      title: t("email_address"),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("phone_number"),
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
      title: t("last_name"),
      dataIndex: "lname",
      sorter: (a, b) => a.lname.localeCompare(b.lname),
    },
    {
      title: t("first_name"),
      dataIndex: "fname",
      sorter: (a, b) => a.fname.localeCompare(b.fname),
    },
    {
      title: t("event_name"),
      dataIndex: "event_name",
      sorter: (a, b) => a.event_name.localeCompare(b.event.name),
    },
    {
      title: t("event_date_and_time"),
      dataIndex: "event_date_list",
      render: (value, record) => {
        const {date, time} = value[0]
        return (
          <>
            <div style={{display: "flex", alignItems: "center"}}>
              <Space>
                {date}
                {time}
              </Space>
            </div>
          </>
        )
      }
    }
  ];


  return (
    <div>
      {" "}
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={data}
        columns={columns}
        size="small"
        bordered
        style={{ marginTop: 32 }}
      />
    </div>
  );
};



export default EventAttendanceRecord;
