import React, { useState, useEffect } from "react";
import { Button, Layout, Modal, Table, Tag, Tooltip } from "antd";
import { EditOutlined, DollarCircleOutlined, SnippetsOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UserAddTokenForm from "../../../components/form/UserAddTokenForm";

const CompanyUserAcListTable = (props) => {
  const { t } = useTranslation();
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "company_user_id";
  const app = useSelector((state) => state.app);
  const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  let { dataList, loading, defaultActiveKey, getAllData, userFilter } = props;

  useEffect(() => {}, []);

  const setTableHeader = () => {
    const columns = [
      {
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Link
                  to={{
                    pathname: "/company/user/form",
                    search: `?id=${value}&key=${defaultActiveKey}`,
                    state: {
                      userInfo: record,
                      companyConfigRc: dataList.companyConfigRc,
                    },
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              <Tooltip title={t("view_user_registration_record")}>
                <Link
                  to={{
                    pathname: "/company/user/registration_record",
                    search: `?company_user_id=${value}`,
                    state: {
                      userInfo: record,
                      companyConfigRc: dataList.companyConfigRc,
                    },
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<SnippetsOutlined />}
                  />
                </Link>
              </Tooltip>
              <Tooltip title={t("view_user_transaction_record")}>
                <Link
                  to={{
                    pathname: "/company/user/transaction_record",
                    search: `?company_user_id=${value}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 4 }}
                    icon={<SnippetsOutlined />}
                  />
                </Link>
              </Tooltip>
              {/* --- comment: disable token first for particular project */}
              {/* <Tooltip title={t("token")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setAddTokenModalVisible(true);
                  }}
                />
              </Tooltip> */}
            </span>
          );
        },
      },
      {
        title: t("company_user_id"),
        dataIndex: "company_user_id",
        sorter: (a, b) => a.company_user_id - b.company_user_id,
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("username"),
        dataIndex: "username",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: t("mobile"),
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      {
        title: t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: t("first_name"),
        dataIndex: "first_name",
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: t("last_name"),
        dataIndex: "last_name",
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        sorter: (a, b) => a.nickname.localeCompare(b.nickname),
      },
      {
        title: t("company_name"),
        dataIndex: "company_name",
        sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      },
      {
        title: t("title"),
        dataIndex: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: t("membership"),
        dataIndex: "membership",
        sorter: (a, b) => a.membership.localeCompare(b.membership),
      },
      {
        title: t("token_owned"),
        dataIndex: "token_own",
        sorter: (a, b) => a.token_own - b.token_own,
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "blue";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "red";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={
          userFilter === "active"
            ? _.filter(dataList.userList, { is_active: 1 })
            : _.filter(dataList.userList, { is_active: 0 })
        }
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
        locale={{
          triggerDesc: t("sort_descend"),
          triggerAsc: t("sort_ascend"),
          cancelSort: t("cancel_sort"),
        }}
      />
      <Modal
        destroyOnClose
        title={t("add_token")}
        visible={addTokenModalVisible}
        footer={null}
        onCancel={() => {
          setAddTokenModalVisible(false);
        }}
      >
        <UserAddTokenForm
          dataObj={selectedRecord}
          setAddTokenModalVisible={setAddTokenModalVisible}
          getAllData={getAllData}
        />
      </Modal>
    </Layout>
  );
};

export default CompanyUserAcListTable;
