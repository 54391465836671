import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  notification,
  Row,
  Col,
  PageHeader,
  Spin
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
import EventEnrollmentList from "../../../components/Event/EventEnrollmentList";
import EventAttendanceRecord from "../../../components/Event/EventAttendanceRecord";
import EventSetting from "../../../components/Event/EventSetting";
import EventInformation from "../../../components/Event/EventInformation";

const debug = require("debug")("app:admin:client:src:EventInfo");

const { TabPane } = Tabs;
const title = "Event Info";
const selectedKey = "event";

const EventInfo = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const event_key = _.last(location.pathname.split("/")) || params.event_key;

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("setting");
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const [eventMeta, setEventMeta] = useState({});
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getEventInfo();
    getEventCategoryList();
  }, [event_key]);

  const getEventInfo = async () => {
    setLoading(true);
    try {
      const resp = await Service.call("get", `/api/event/${event_key}`);

      if (resp.status === 1) {
        setEventInfo(resp.data.eventData);
        setEventMeta(resp.data.eventData.eventMeta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getEventCategoryList = async () => {
    try {
      let resp = await Service.call("get", "/api/event/category", {event_key});
      const { data } = resp;
      const list = _.map(data, (item) => {
        return {
          value: item.event_category_id,
          label: item.title
        };
      });
      setCategories(list);
    } catch (error) {
      console.error(error);
    }
  };

  const getEnrollmentList = async () => {
    try {
      let resp = await Service.call("get", "/api/event/enrollment/list", { event_key });
      if (resp.status === 1) {
        setEnrollmentList(resp.data.enrollmentList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavAndSideFrame {...props} title="" selectedKey={selectedKey}>
      <PageHeader
        onBack={() => history.push("/company/event")}
        title={`${title} - ${event_key}`}
      />
      <Spin spinning={loading}>
        <Tabs onChange={setSelectedTab} activeKey={selectedTab}>
          <TabPane tab={t("event_information")} key="info">
            <EventInformation
              dataObj={eventInfo}
              eventMeta={eventMeta}
              categories={categories}
              getEventCategoryList={getEventCategoryList}
              getEventInfo={getEventInfo}
            />
          </TabPane>
          <TabPane tab={t("event_setting")} key="setting">
            <EventSetting
              dataObj={eventInfo}
              eventMeta={eventMeta}
              getEventInfo={getEventInfo}
            />
          </TabPane>
          <TabPane tab={t("enrollment_list")} key="enrollment">
            <EventEnrollmentList
              event_key={event_key}
              getAllData={getEnrollmentList}
              enrollmentList={enrollmentList}
            />
          </TabPane>
          <TabPane tab={t("attendance_record")} key="attendance">
            <EventAttendanceRecord />
          </TabPane>
        </Tabs>
      </Spin>
    </NavAndSideFrame>
  );
};

export default EventInfo;
