import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Row,
  Col,
  Card,
  Switch,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";

const { Option } = Select;

const LOGIN_METHOD = {
  USERNAME: 1,
  EMAIL: 2,
  MOBILE: 3,
  BOTH_REQUIRE_WITH_EMAIL_LOGIN: 4,
};

const UserAcInfoForm = (props) => {
  const { t } = useTranslation();
  const { initialValues, setUser, companyConfigRc } = props;
  const id = initialValues.company_user_id;
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialValuesForm, setInitialValues] = useState({
    mobilePrefix: "+852",
    is_admin: 0,
  });

  const [UserForm] = Form.useForm();
  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues };
      setInitialValues(newObject);
      let split_mobile = _.split(initialValues.mobile, "-");
      setNumber(_.last(split_mobile));
    }
  }, [initialValues]);

  useEffect(() => {
    UserForm.resetFields();
  }, [initialValuesForm]);

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      let postData = {
        ...formData,
        preferred_language: "zh",
        company_user_id: id,
        is_admin: formData.is_admin === true ? 1 : 0,
        mobile: `${_.last(_.split(formData.mobile, "-"))}`,
      };
      // Check password when creating user
      if (id === 0 && !validatePassword(formData.password)) {
        return;
      }
      let method = "PUT";
      if (id > 0) {
        method = "PATCH";
      }
      let resp = await Service.call(method, `/api/company/user`, postData);

      if (!resp || resp.status === -1) {
        setLoading(false);
        notification.error({
          message: `${t("error")}: ${resp.errorMessage}[${resp.errorCode}]`,
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      const { data } = resp;
      setUser(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      notification.error({
        message: t("error"),
        description: t("password_length_too_short"),
      });
      return false;
    }
    if (password.search(/[a-z]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_character"),
      });
      return false;
    }
    if (password.search(/[0-9]/i) < 0) {
      notification.error({
        message: t("error"),
        description: t("password_should_include_number"),
      });
      return false;
    }
    return true;
  };

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setNumber(value);
      UserForm.setFieldsValue({ mobile: value });
    }
  };

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValuesForm}
      onFinish={onFinish}
    >
      <Row gutter={[24, 24]}>
        <Col md={24} lg={16}>
          <Card>
            <Form.Item
              label={t("username")}
              name="username"
              rules={[
                {
                  required: _.includes(
                    [LOGIN_METHOD.USERNAME],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_username"),
                },
              ]}
            >
              <Input
                maxLength={40}
                disabled={
                  LOGIN_METHOD.USERNAME !== companyConfigRc?.login_method
                }
              />
            </Form.Item>
            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("invalid_email"),
                },
                {
                  required: _.includes(
                    [
                      LOGIN_METHOD.EMAIL,
                      LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                    ],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_email"),
                },
              ]}
            >
              <Input maxLength={128} />
            </Form.Item>

            <Form.Item
              // contact_person_mobile
              label={t("moblie")}
              name="mobile"
              rules={[
                {
                  required: _.includes(
                    [
                      LOGIN_METHOD.MOBILE,
                      LOGIN_METHOD.BOTH_REQUIRE_WITH_EMAIL_LOGIN,
                    ],
                    companyConfigRc?.login_method
                  ),
                  message: t("please_input_mobile"),
                },
              ]}
            >
              <Tooltip>
                <Input
                  value={number}
                  onChange={onNumberChange}
                  addonBefore={
                    <Form.Item
                      name="mobilePrefix"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: t("select_required"),
                        },
                      ]}
                    >
                      <Select style={{ width: 80 }}>
                        <Option value="+852">+852</Option>
                        <Option value="+853">+853</Option>
                        <Option value="+86">+86</Option>
                      </Select>
                    </Form.Item>
                  }
                  style={{ width: "100%" }}
                  maxLength={15}
                />
              </Tooltip>
            </Form.Item>

            {!id && (
              <Form.Item
                label={t("password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            {!id && (
              <Form.Item
                label={t("confirm_password")}
                name="confirm_password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("input_required"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("password_not_match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}

            {/* <Form.Item label={t("first_name")} name="first_name">
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item label={t("last_name")} name="last_name">
              <Input maxLength={64} />
            </Form.Item> */}

            <Form.Item
              label={t("nickname")}
              name="nickname"
              required={[
                {
                  require: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label={t("title")}
              name="title"
              required={[
                {
                  require: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label={t("company_name")}
              name="company_name"
              required={[
                {
                  require: true,
                  message: t("input_required"),
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>
            

            <Form.Item label={t("gender")} name="gender">
              <Select>
                <Option value="M">{t("male")}</Option>
                <Option value="F">{t("female")}</Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
              label={t('preferred_language')}
              name="preferred_language"
            >
              <Select>
                <Option value="zh">{t('zh')}</Option>
                <Option value="en">{t('en')}</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              label={t("is_admin")}
              name="is_admin"
              valuePropName="checked"
            >
              <Switch checked={initialValues.is_admin === 1} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("submit")}
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <h4>{t("last_active_time")}</h4>
            <p>
              {initialValues.last_active_time
                ? moment
                    .unix(initialValues.last_active_time)
                    .format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            <h4>{t("create_time")}</h4>
            <p>
              {initialValues.ctime
                ? moment.unix(initialValues.ctime).format("YYYY-MM-DD HH:mm")
                : "Na"}
            </p>
            <Form.Item
              label={t("is_verified_email")}
              name="is_email_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item>
            <Form.Item
              label={t("is_verified_mobile")}
              name="is_mobile_verified"
              valuePropName="checked"
            >
              <Switch disabled />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default UserAcInfoForm;
