import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";


const PaymentMethodTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "notification_queue_id";
  // get parms from props
  const {
    loading,
    dataList
  } = props;

  const setTableHeader = () => {
    const columns = [
      {
        title: "ID",
        key: "payment_method_id",
        dataIndex: "payment_method_id",
        render: (value) => t(value),
      },
      {
        title: "Payment Method",
        key: "payment_method_type",
        dataIndex: "payment_method_type",
        render: (value) => t(value),
      },
      {
        title: t("ctime"),
        key: "ctime",
        dataIndex: "ctime",
        width: 200,
        render: (value) => (moment.unix(value).format("YYYY-MM-DD HH:mm")),
      },
    ];
    return columns;
  };

  return (
    <>
      <Table
        rowKey={tableIDName}
        scrollSize="max-content"
        columns={setTableHeader()}
        dataSource={dataList}
        loading={loading}
      />
    </>
  );
};

export default PaymentMethodTable;
