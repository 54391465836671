import React, { useState, useEffect } from "react";
import { Button, Layout, Table, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

const HtmlToReactParser = require("html-to-react").Parser;

const tableIDName = "company_door_qrcode";

const htmlToReactParser = new HtmlToReactParser();

const CompanyEmailTable = (props) => {
  const [dataList, setDataList] = useState([]);

  const { dataObj, loading, setSelectedRecord } = props;

  useEffect(() => {
    setDataList(dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: "Operation",
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title="Edit">
                <Button
                  shape="circle"
                  style={{
                    color: "black",
                    marginRight: 8,
                  }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    props.setModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "email_type",
      },
      {
        title: "Subject",
        dataIndex: "subject",
      },
      {
        title: "Body Content",
        dataIndex: "body_content",
        render: (value) => htmlToReactParser.parse(value),
      },
    ];
    return columns;
  };

  return (
    <Layout>
      <Table
        loading={loading}
        rowKey={tableIDName}
        scroll={{ x: "max-content" }}
        dataSource={dataList}
        columns={setTableHeader()}
      />
    </Layout>
  );
};

export default CompanyEmailTable;
