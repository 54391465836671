import {
  Button, Checkbox, Col, Modal, Row, Space
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MailOutlined } from "@ant-design/icons";

import * as Service from "../../core/Service";

const SendEmailPopup = ({
  modalVisible, setModalVisible, selectedUsers, event_key
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  const handleSendEmail = async () => {
    // fetch api
    const sendReminder = options.includes("reminder");
    const sendInvitation = options.includes("invitation");

    if (sendReminder) {
      const data = {
        event_key,
        event_user_id: selectedUsers.map((user) => user.event_user_id),
        type: "reminder",
      };
      const resp = await Service.call("post", "/api/email/send_event_email/", data);
    }

    if (sendInvitation) {
      const data = {
        event_key,
        event_user_id: selectedUsers.map((user) => user.event_user_id),
        type: "invitation",
      };
      const resp = await Service.call("post", "/api/email/send_event_email/", data);
    }
    setModalVisible(false);
  };

  const selectOptions = [
    {
      label: (
        <Space>
          <MailOutlined />
          {t("invitation_mail_to_selected_user")}
        </Space>
      ),
      value: "invitation",
    },
    {
      label: (
        <Space>
          <MailOutlined />
          {t("reminder_mail_to_selected_user")}
        </Space>
      ),
      value: "reminder",
    },
  ];
  return (
    <Modal
      title={t("send_mail")}
      centered
      visible={modalVisible}
      footer={false}
      onCancel={() => setModalVisible(false)}
      className="send-email-modal"
      style={{maxWidth: 352}}
    >
      <Checkbox.Group options={selectOptions} onChange={setOptions} />
      <Row gutter={16} justify="end" style={{marginTop: 32}}>
        <Col>
          <Button type="primary" ghost className="action-btn" onCancel={() => setModalVisible(false)}>
            {t("cancel")}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="action-btn" onClick={handleSendEmail}>{t("confirm")}</Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendEmailPopup;
