// import { Router, withTranslation, useTranslation, Link } from '../lib-client/i18n';
import { withTranslation, useTranslation, Link } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Form, Input, Row, Col, Button, Radio, DatePicker, Select, Tabs, Modal, message, notification
} from "antd";
// import dynamic from 'next/dynamic'
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import { openNotificationWithIcon, onFinishFailed } from "./ErrorMessage";
// import * as Service from '../../lib-client/service';
import * as Service from "../../core/Service";
import FormUploadFile from "./FormUploadFile";
import TinymceWrapper from "../TinymceWrapper";

const debug = require("debug")("app:components:form/StaticPageEditForm");


const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 }
  },
};

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
    },
    sm:
    {
      offset: 0,
    },
    md:
    {
      offset: 0,
    }
  },
};

const editorStyle = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24, },
    md: { span: 15, }
  },
};

const StaticPageEditForm = (props) => {
  const { t } = useTranslation();

  const { recordId, selectedFormData, closeMethod } = props;
  let { meta_value, meta_key } = selectedFormData;

  const [form] = Form.useForm();
  const [postContent, setPostContent] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  useEffect(() => {
    setPostContent(_.toString(meta_value));
    if (meta_value !== "") {
      let path = STATIC_SERVER_URL + '/' + meta_value;
      setImageURL(path);
    }
  }, []);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = STATIC_SERVER_URL + "/" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const determineInputType = () => {

    if (_.some(["background", "image", "banner", "logo"], (el) => _.includes(meta_key, el))) {
      return (
        <div>
          <FormUploadFile
            data={{ scope: "public" }}
            onChange={uploadOnChange}
            imageURL={imageURL}
          />
        </div>
      )
    }


    if (_.some(["content", "contact", "desc", "company_address", "about_us", "copy_right"], (el) => _.includes(meta_key, el))) { // Specific input type by meta key
      return (
        <div>
          <TinymceWrapper
            initialValue={postContent}
            onChange={setPostContent}
          />
        </div>
      );
    }

    // Default input
    return (
      <Input
        value={(postContent)}
        onChange={(evt) => setPostContent(evt.target.value)}
      />
    );
  };

  return (
    <Form
      form={form}
      initialValues={selectedFormData}
      onFinish={async (values) => {
        let method = "post";
        if (recordId !== 0) { //  Edit
          method = "patch";
          values.company_site_meta_id = recordId;
          values.meta_value = fileinfo.filepath ? fileinfo.filepath : postContent !=="" ? postContent : values.meta_value;
        }
        let result = await Service.call(method, "/api/admin/meta", values);

        if (result.status !== 1) {
          return notification.error({ message: '提交失敗', description: t(result.errorCode) });
        }

        notification.success({ message: '提交成功' });
        closeMethod();
      }}
    >
      <Form.Item
        label={t("key")}
        name="meta_key"
        rules={[{ required: true }]}
      >
        <Select disabled={recordId}>
          <Option value="company_info_name_zh_hant">{`${t("company_name")} (${t("zh_hant")})`}</Option>
          <Option value="company_info_name_en">{`${t("company_name")} (${t("en")})`}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t("static_content")}
        // name="meta_value"
        rules={[
          {
            required: true,
            message: `${t("common:content")}${t("common:is_required")}`
          },
        ]}
      >

        {determineInputType()}
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t("submit")}
      </Button>
    </Form>
  );
};
export default StaticPageEditForm;
