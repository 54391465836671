import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Form, Input, Spin, notification, message } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import * as Service from "../../core/Service";
import { formItemLayout, tailLayout } from "../ModalLayout";

const UserAddTokenForm = (props) => {
  const { dataObj, setAddTokenModalVisible, getAllData } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(dataObj);
  }, [dataObj]);
  const onFinish = async (postObj) => {
    setLoading(true);
    if (_.isEmpty(dataObj)) {
      return message.error(t("user ID not Found"));
    }
    postObj.company_user_id = dataObj.company_user_id;
    let result = await Service.call("put", "/api/token/company/user", postObj);
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: t(result.errorCode),
      });
    }
    notification.success({ message: "Success!" });
    getAllData();
    setAddTokenModalVisible(false);
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        size="small"
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item label={t("name")} name="username">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("email")} name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t("quantity")}
          rules={[{ required: true, message: "Please input token number." }]}
          name="quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("reason")}
          name="remarks"
          rules={[{ required: true, message: "Please input token number." }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserAddTokenForm;
