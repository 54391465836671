import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  message, Table, Spin, PageHeader, Tag
} from "antd";
import moment from "moment";
import {
  useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import * as Service from "../../../core/Service";
import NavAndSideFrame from "../../../components/NavAndSideFrame";

const CompanyUserRegistrationRecord = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { company_user_id } = queryString.parse(location.search);
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  // side bar
  const selectedKey = "user_list";
  const openKeys = "user";
  // table row
  const tableIDName = "event_enrollment_id";

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      let requestQuery = {
        company_user_id,
      };
      let resp = await Service.call("get", "/api/event/getAllRegistrationForUser", requestQuery);
      if (resp.status !== 1) {
        message.error(resp.errorMessage);
      }
      if (resp.status === 1) {
        setDataList(resp.data.enrollmentList);
      }
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = value;
    switch (statusValue) {
      case 1:
        displayStr = "Approved";
        tagColor = "green";
        break;
      case 0:
        displayStr = "Pending";
        tagColor = "cyan";
        break;
      case -1:
        displayStr = "Rejected";
        tagColor = "red";
        break;
      default:
        displayStr = "Error";
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t("event_enrollment_id"),
        dataIndex: "event_enrollment_id"
      },
      {
        title: t("event_id"),
        dataIndex: "event_id",
      },
      {
        title: t("event_date_id"),
        dataIndex: "event_date_id",
      },
      {
        title: t("event_user_id"),
        dataIndex: "event_user_id",
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value) => displayStatus(value),
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: t("update_time"),
        dataIndex: "utime",
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
      },
    ];
    return columns;
  };
  return (
    <NavAndSideFrame
      {...props}
      title="Registration Record"
      selectedKey={selectedKey}
      openKeys={openKeys}
    >
      <PageHeader
        onBack={() => history.push("/company/user/list")}
        title="Go Back"
      />
      <Spin size="large" spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
          size="small"
        />
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyUserRegistrationRecord;
